/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSurvey = /* GraphQL */ `
  mutation CreateSurvey(
    $input: CreateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    createSurvey(input: $input, condition: $condition) {
      id
      surveyStatus
      key
      title
      questions
      optionalMessage
      optionalScreenedOutMessage
      optionalClosedMessage
      optionalCompletedMessage
      isEmailMode
      isJournaling
      isPhoneOnly
      redirectClosed
      redirectScreenedOut
      redirectCompleted
      version
      customCss
      sections
      isScreening
      screenOutData
      autoMigrate
      startDate
      stopDate
      minPanelists
      maxPanelists
      ResponseUserDetails {
        items {
          firstName
          lastInitial
          journalingDay
          languageCode
          emailHash
          answers
          isFinished
          isMigrated
          surveyID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSurvey = /* GraphQL */ `
  mutation UpdateSurvey(
    $input: UpdateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    updateSurvey(input: $input, condition: $condition) {
      id
      surveyStatus
      key
      title
      questions
      optionalMessage
      optionalScreenedOutMessage
      optionalClosedMessage
      optionalCompletedMessage
      isEmailMode
      isJournaling
      isPhoneOnly
      redirectClosed
      redirectScreenedOut
      redirectCompleted
      version
      customCss
      sections
      isScreening
      screenOutData
      autoMigrate
      startDate
      stopDate
      minPanelists
      maxPanelists
      ResponseUserDetails {
        items {
          firstName
          lastInitial
          journalingDay
          languageCode
          emailHash
          answers
          isFinished
          isMigrated
          surveyID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSurvey = /* GraphQL */ `
  mutation DeleteSurvey(
    $input: DeleteSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    deleteSurvey(input: $input, condition: $condition) {
      id
      surveyStatus
      key
      title
      questions
      optionalMessage
      optionalScreenedOutMessage
      optionalClosedMessage
      optionalCompletedMessage
      isEmailMode
      isJournaling
      isPhoneOnly
      redirectClosed
      redirectScreenedOut
      redirectCompleted
      version
      customCss
      sections
      isScreening
      screenOutData
      autoMigrate
      startDate
      stopDate
      minPanelists
      maxPanelists
      ResponseUserDetails {
        items {
          firstName
          lastInitial
          journalingDay
          languageCode
          emailHash
          answers
          isFinished
          isMigrated
          surveyID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createResponseUserDetail = /* GraphQL */ `
  mutation CreateResponseUserDetail(
    $input: CreateResponseUserDetailInput!
    $condition: ModelResponseUserDetailConditionInput
  ) {
    createResponseUserDetail(input: $input, condition: $condition) {
      firstName
      lastInitial
      journalingDay
      languageCode
      emailHash
      answers
      isFinished
      isMigrated
      surveyID
      survey {
        id
        surveyStatus
        key
        title
        questions
        optionalMessage
        optionalScreenedOutMessage
        optionalClosedMessage
        optionalCompletedMessage
        isEmailMode
        isJournaling
        isPhoneOnly
        redirectClosed
        redirectScreenedOut
        redirectCompleted
        version
        customCss
        sections
        isScreening
        screenOutData
        autoMigrate
        startDate
        stopDate
        minPanelists
        maxPanelists
        ResponseUserDetails {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateResponseUserDetail = /* GraphQL */ `
  mutation UpdateResponseUserDetail(
    $input: UpdateResponseUserDetailInput!
    $condition: ModelResponseUserDetailConditionInput
  ) {
    updateResponseUserDetail(input: $input, condition: $condition) {
      firstName
      lastInitial
      journalingDay
      languageCode
      emailHash
      answers
      isFinished
      isMigrated
      surveyID
      survey {
        id
        surveyStatus
        key
        title
        questions
        optionalMessage
        optionalScreenedOutMessage
        optionalClosedMessage
        optionalCompletedMessage
        isEmailMode
        isJournaling
        isPhoneOnly
        redirectClosed
        redirectScreenedOut
        redirectCompleted
        version
        customCss
        sections
        isScreening
        screenOutData
        autoMigrate
        startDate
        stopDate
        minPanelists
        maxPanelists
        ResponseUserDetails {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteResponseUserDetail = /* GraphQL */ `
  mutation DeleteResponseUserDetail(
    $input: DeleteResponseUserDetailInput!
    $condition: ModelResponseUserDetailConditionInput
  ) {
    deleteResponseUserDetail(input: $input, condition: $condition) {
      firstName
      lastInitial
      journalingDay
      languageCode
      emailHash
      answers
      isFinished
      isMigrated
      surveyID
      survey {
        id
        surveyStatus
        key
        title
        questions
        optionalMessage
        optionalScreenedOutMessage
        optionalClosedMessage
        optionalCompletedMessage
        isEmailMode
        isJournaling
        isPhoneOnly
        redirectClosed
        redirectScreenedOut
        redirectCompleted
        version
        customCss
        sections
        isScreening
        screenOutData
        autoMigrate
        startDate
        stopDate
        minPanelists
        maxPanelists
        ResponseUserDetails {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createRegisteredUser = /* GraphQL */ `
  mutation CreateRegisteredUser(
    $input: CreateRegisteredUserInput!
    $condition: ModelRegisteredUserConditionInput
  ) {
    createRegisteredUser(input: $input, condition: $condition) {
      email
      pxFirstLastInitial
      pxIndex
      active
      totalRewardThisYear
      rewardPerYear {
        year
        reward
      }
      firstName
      lastName
      address
      yearOfBirth
      gender
      ethnicity
      education
      employment
      householdIncome
      industry
      jobRole
      householdComp
      moreDemographics
      profilePict
      lastDemographicUpdate
      SSN
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateRegisteredUser = /* GraphQL */ `
  mutation UpdateRegisteredUser(
    $input: UpdateRegisteredUserInput!
    $condition: ModelRegisteredUserConditionInput
  ) {
    updateRegisteredUser(input: $input, condition: $condition) {
      email
      pxFirstLastInitial
      pxIndex
      active
      totalRewardThisYear
      rewardPerYear {
        year
        reward
      }
      firstName
      lastName
      address
      yearOfBirth
      gender
      ethnicity
      education
      employment
      householdIncome
      industry
      jobRole
      householdComp
      moreDemographics
      profilePict
      lastDemographicUpdate
      SSN
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteRegisteredUser = /* GraphQL */ `
  mutation DeleteRegisteredUser(
    $input: DeleteRegisteredUserInput!
    $condition: ModelRegisteredUserConditionInput
  ) {
    deleteRegisteredUser(input: $input, condition: $condition) {
      email
      pxFirstLastInitial
      pxIndex
      active
      totalRewardThisYear
      rewardPerYear {
        year
        reward
      }
      firstName
      lastName
      address
      yearOfBirth
      gender
      ethnicity
      education
      employment
      householdIncome
      industry
      jobRole
      householdComp
      moreDemographics
      profilePict
      lastDemographicUpdate
      SSN
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createRatingEvent = /* GraphQL */ `
  mutation CreateRatingEvent(
    $input: CreateRatingEventInput!
    $condition: ModelRatingEventConditionInput
  ) {
    createRatingEvent(input: $input, condition: $condition) {
      email
      type
      event
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateRatingEvent = /* GraphQL */ `
  mutation UpdateRatingEvent(
    $input: UpdateRatingEventInput!
    $condition: ModelRatingEventConditionInput
  ) {
    updateRatingEvent(input: $input, condition: $condition) {
      email
      type
      event
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteRatingEvent = /* GraphQL */ `
  mutation DeleteRatingEvent(
    $input: DeleteRatingEventInput!
    $condition: ModelRatingEventConditionInput
  ) {
    deleteRatingEvent(input: $input, condition: $condition) {
      email
      type
      event
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const unsubscribe = /* GraphQL */ `
  mutation Unsubscribe(
    $functionName: String!
    $sourceEmail: String!
    $email: String!
    $emailHash: String!
  ) {
    unsubscribe(
      functionName: $functionName
      sourceEmail: $sourceEmail
      email: $email
      emailHash: $emailHash
    )
  }
`;
export const virtualinterviewerclient = /* GraphQL */ `
  mutation Virtualinterviewerclient(
    $functionName: String!
    $studyId: String!
    $panelist: String!
    $params: AWSJSON!
  ) {
    virtualinterviewerclient(
      functionName: $functionName
      studyId: $studyId
      panelist: $panelist
      params: $params
    )
  }
`;
