import { getLocalStorage } from "../Models/data/useLocalStorage";

export const capitalizeString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const checkIfAnswered = (emailHash, index) => {
  const storedAns = getLocalStorage(`answers-${params.id}-${emailHash}`);
  return storedAns.some((item) => item.index === index);
};

export const createEvent = (question, value, checked) => {
  return {
    target: {
      name: question,
      value: value,
      checked: checked,
    },
  };
};

export const getSocialMediaPlatform = (userAgent) => {
  if (userAgent.includes("Facebook")) {
    // Facebook platform detected
    return "Facebook platform";
  } else if (userAgent.includes("Twitter")) {
    // Twitter platform detected
    return "Twitter platform";
  } else if (userAgent.includes("Instagram")) {
    // Instagram platform detected
    if (userAgent.includes("Android")) {
      return "Android Instagram platform";
    } else {
      return "Instagram platform";
    }
  } else if (userAgent.includes("LinkedIn")) {
    // LinkedIn platform detected
    return "LinkedIn platform";
  } else if (userAgent.includes("FBAN")) {
    // LinkedIn platform detected
    return "Messenger platform";
  } else if (userAgent.includes("FB_IAB")) {
    // LinkedIn platform detected
    if (userAgent.includes("Orca-Android")) {
      return "Android Messenger platform";
    } else {
      return "Android Facebook platform";
    }
  }
};

export const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});
