import React, { useEffect, useState } from "react";

const CountDownTimer = ({ hoursMinSecs }) => {
  const { hours = 0, minutes = 0, seconds = 59 } = hoursMinSecs;
  const [[hrs, mins, secs], setTime] = useState([hours, minutes, seconds]);

  const tickRev = () => {
    if (secs === 59 && mins !== 59) {
      setTime([hrs, mins + 1, 0]);
    } else if (secs === 59 && mins === 59) {
      setTime([hrs + 1, 0, 0]);
    } else {
      setTime([hrs, mins, secs + 1]);
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => tickRev(), 1000);
    return () => clearInterval(timerId);
  });

  return (
    <div>
      {/* ${hrs.toString().padStart(2, "0")}: */}
      <span className="timer-text">{`${mins.toString().padStart(2, "0")}:${secs
        .toString()
        .padStart(2, "0")}`}</span>
    </div>
  );
};

export default CountDownTimer;
