import axios from "axios";
async function SendAudio(audio, id, panelist) {
  const formData = new FormData();
  if (audio) formData.append("file", audio, "audiofile.mp3");
  formData.append("Text", "");
  formData.append("id", id);
  formData.append("panelist", panelist);
  console.log("formData", formData);

  try {
    const axiosPost = axios.post(
      "https://qijfhwrbjoib6kvdll2at3of5q0srbxm.lambda-url.us-east-1.on.aws/",
      formData
    );
    const response = await axiosPost;
    console.log("responseAxios", response);
    return response.data;
  } catch (error) {
    console.error("Error sending audio:", error);
  }
}

export default SendAudio;
