import open from "./images/openIn.png";
import openFB from "./images/openInFB.png";
import dots from "./images/dots.png";
import dotsFB from "./images/dotsFB.png";

export const errorData = [
  {
    text: "	Look for three Horizontal dots at the top right corner of the screen.	",
    img: dots,
  },

  { text: "	Tap on the three dots and a menu will appear.	", img: false },
  {
    text: '	From the menu, select the "Open in Chrome" option.	',
    img: open,
  },
  { text: "	You will be redirected to the Chrome browser.	", img: false },
  {
    text: "	The link or website should now load and be accessible in your Chrome browser.	",
    img: false,
  },
];

export const errorDataFB = [
  {
    text: "	Look for three vertical dots at the top right corner of the screen.	",
    img: dotsFB,
  },
  { text: "	Tap on the three dots and a menu will appear.	", img: false },

  {
    text: '	From the menu, select the "Open in system browser" option.	',
    img: openFB,
  },
  { text: "	You will be redirected to the Chrome browser.	", img: false },
  {
    text: "	The link or website should now load and be accessible in your Chrome browser.	",
    img: false,
  },
];
