export function getLocalStorage(key) {
    const storedValue = localStorage.getItem(key);
    if (storedValue !== null) {
      return JSON.parse(storedValue);
    }
    return [];
  }
  
  export function setLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  
  export function removeItem(key) {
    localStorage.removeItem(key);
  }
  
  export function clear() {
    localStorage.clear();
  }
  