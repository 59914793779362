/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSurvey = /* GraphQL */ `
  query GetSurvey($id: ID!) {
    getSurvey(id: $id) {
      id
      surveyStatus
      key
      title
      questions
      optionalMessage
      optionalScreenedOutMessage
      optionalClosedMessage
      optionalCompletedMessage
      isEmailMode
      isJournaling
      isPhoneOnly
      redirectClosed
      redirectScreenedOut
      redirectCompleted
      version
      customCss
      sections
      isScreening
      screenOutData
      autoMigrate
      startDate
      stopDate
      minPanelists
      maxPanelists
      ResponseUserDetails {
        items {
          firstName
          lastInitial
          journalingDay
          languageCode
          emailHash
          answers
          isFinished
          isMigrated
          surveyID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSurveys = /* GraphQL */ `
  query ListSurveys(
    $id: ID
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSurveys(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        surveyStatus
        key
        title
        questions
        optionalMessage
        optionalScreenedOutMessage
        optionalClosedMessage
        optionalCompletedMessage
        isEmailMode
        isJournaling
        isPhoneOnly
        redirectClosed
        redirectScreenedOut
        redirectCompleted
        version
        customCss
        sections
        isScreening
        screenOutData
        autoMigrate
        startDate
        stopDate
        minPanelists
        maxPanelists
        ResponseUserDetails {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getResponseUserDetail = /* GraphQL */ `
  query GetResponseUserDetail($surveyID: ID!, $emailHash: String!) {
    getResponseUserDetail(surveyID: $surveyID, emailHash: $emailHash) {
      firstName
      lastInitial
      journalingDay
      languageCode
      emailHash
      answers
      isFinished
      isMigrated
      surveyID
      survey {
        id
        surveyStatus
        key
        title
        questions
        optionalMessage
        optionalScreenedOutMessage
        optionalClosedMessage
        optionalCompletedMessage
        isEmailMode
        isJournaling
        isPhoneOnly
        redirectClosed
        redirectScreenedOut
        redirectCompleted
        version
        customCss
        sections
        isScreening
        screenOutData
        autoMigrate
        startDate
        stopDate
        minPanelists
        maxPanelists
        ResponseUserDetails {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listResponseUserDetails = /* GraphQL */ `
  query ListResponseUserDetails(
    $surveyID: ID
    $emailHash: ModelStringKeyConditionInput
    $filter: ModelResponseUserDetailFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listResponseUserDetails(
      surveyID: $surveyID
      emailHash: $emailHash
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        firstName
        lastInitial
        journalingDay
        languageCode
        emailHash
        answers
        isFinished
        isMigrated
        surveyID
        survey {
          id
          surveyStatus
          key
          title
          questions
          optionalMessage
          optionalScreenedOutMessage
          optionalClosedMessage
          optionalCompletedMessage
          isEmailMode
          isJournaling
          isPhoneOnly
          redirectClosed
          redirectScreenedOut
          redirectCompleted
          version
          customCss
          sections
          isScreening
          screenOutData
          autoMigrate
          startDate
          stopDate
          minPanelists
          maxPanelists
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRegisteredUser = /* GraphQL */ `
  query GetRegisteredUser($email: String!) {
    getRegisteredUser(email: $email) {
      email
      pxFirstLastInitial
      pxIndex
      active
      totalRewardThisYear
      rewardPerYear {
        year
        reward
      }
      firstName
      lastName
      address
      yearOfBirth
      gender
      ethnicity
      education
      employment
      householdIncome
      industry
      jobRole
      householdComp
      moreDemographics
      profilePict
      lastDemographicUpdate
      SSN
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listRegisteredUsers = /* GraphQL */ `
  query ListRegisteredUsers(
    $email: String
    $filter: ModelRegisteredUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRegisteredUsers(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        pxFirstLastInitial
        pxIndex
        active
        totalRewardThisYear
        rewardPerYear {
          year
          reward
        }
        firstName
        lastName
        address
        yearOfBirth
        gender
        ethnicity
        education
        employment
        householdIncome
        industry
        jobRole
        householdComp
        moreDemographics
        profilePict
        lastDemographicUpdate
        SSN
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const panelistIdByFirtLastInitialIndex = /* GraphQL */ `
  query PanelistIdByFirtLastInitialIndex(
    $pxFirstLastInitial: String!
    $pxIndex: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegisteredUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    panelistIdByFirtLastInitialIndex(
      pxFirstLastInitial: $pxFirstLastInitial
      pxIndex: $pxIndex
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        pxFirstLastInitial
        pxIndex
        active
        totalRewardThisYear
        rewardPerYear {
          year
          reward
        }
        firstName
        lastName
        address
        yearOfBirth
        gender
        ethnicity
        education
        employment
        householdIncome
        industry
        jobRole
        householdComp
        moreDemographics
        profilePict
        lastDemographicUpdate
        SSN
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRatingEvent = /* GraphQL */ `
  query GetRatingEvent(
    $email: String!
    $type: RatingEventType!
    $timestamp: AWSTimestamp!
  ) {
    getRatingEvent(email: $email, type: $type, timestamp: $timestamp) {
      email
      type
      event
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const listRatingEvents = /* GraphQL */ `
  query ListRatingEvents(
    $email: String
    $typeTimestamp: ModelratingEventPrimaryCompositeKeyConditionInput
    $filter: ModelRatingEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRatingEvents(
      email: $email
      typeTimestamp: $typeTimestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        type
        event
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
