import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Modal, IconButton } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ReactPlayer from "react-player/lazy";
import { Storage } from "aws-amplify";
import { Labels, modalContainer } from "../assets/css/sxStyles";
import "../App.css";

const StimulusModal = (props) => {
  const [open, setOpen] = useState(false);
  const [delay] = useState(3000); // Delay in milliseconds

  //   media stuff
  const [videoURL, setVideoURL] = useState("");
  const [audioURL, setAudioURL] = useState("");
  const [imageURL, setImageURL] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (props.autoPlay) {
      const timeout = setTimeout(() => {
        setOpen(props.autoPlay);
      }, delay);

      return () => clearTimeout(timeout); // Clear the timeout if the component unmounts
    }
  }, [props.autoPlay]);

  useEffect(() => {
    async function fetchData() {
      let result = "";
      try {
        result = await Storage.get(props.url, {
          expires: 86400,
        });
      } catch (error) {
        console.error(error);
      }

      switch (props.type) {
        case "Video":
          setVideoURL(result);
          break;
        case "Audio":
          setAudioURL(result);
          break;
        case "Image":
          setImageURL(result);
          break;
        default:
          break;
      }
    }
    fetchData();
  }, [props]);

  return (
    <div className="modal-container">
      <Button variant="text" onClick={handleOpen} disabled={props.allow}>
        {props.type !== "" && (
          <Typography sx={Labels}>
            {props.type === "Video" && "play video"}

            {props.type === "Audio" && "play Audio"}

            {props.type === "Image" && "view image"}
          </Typography>
        )}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalContainer} className="modal-box">
          <IconButton
            aria-label="delete"
            size="large"
            onClick={handleClose}
            className="modal-close"
          >
            <CloseOutlinedIcon fontSize="inherit" sx={{ color: "white" }} />
          </IconButton>
          {props.type === "Video" && (
            <ReactPlayer
              controls
              playsinline={true}
              width="100%"
              height="100%"
              url={videoURL}
              playing={true}
              onEnded={handleClose}
            />
          )}

          {props.type === "Audio" && (
            <ReactPlayer
              playsinline={true}
              className="audio-prompt-container"
              width="90%"
              height={50}
              url={audioURL}
              playing={true}
              controls={true}
              onEnded={handleClose}
            />
          )}

          {props.type === "Image" && (
            <img src={imageURL} alt="" width="100%" height="100%" />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default StimulusModal;
