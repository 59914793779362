import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Amplify } from "@aws-amplify/core";
import awsExports from "./aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";

Amplify.configure(awsExports);
Auth.currentCredentials()
  .then((d) => console.log("data: ", d))
  .catch((e) => console.log("error: ", e));

ReactDOM.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <App />
    </Authenticator.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
