import React from "react";
import { Typography, Box } from "@mui/material";
// css
import "../App.css";

const TermAndConditions = ({ onScrolledDown }) => {
  const onScroll = (e) => {
    if (onScrolledDown) onScrolledDown(true);
  };
  return (
    <Box
      sx={{
        m: 1,
        p: 0.1,
        border: "1px solid #aaaaaa",
        overflowY: "scroll",
        height: "50vh",
      }}
      onScroll={onScroll}
    >
      <Typography
        variant="body2"
        gutterBottom
        sx={{ margin: "1px 8px 1px 8px" }}
        textAlign={"justify"}
      >
        Pyxis.ai ("Company," "we," or "us") is committed to protecting the
        privacy of our panelists. This Privacy Policy explains how we collect,
        use, and disclose information about our panelists who sign up to
        participate in our paid studies. By signing up to be a panelist, you
        agree to the terms of this Privacy Policy and our Terms of Use. If you
        do not agree with the terms of this Privacy Policy or our Terms of Use,
        please do not sign up to be a panelist.
        <br />
        <br />
        <b>Information Collection and Use:</b>
        <br />
        When you sign up to be a panelist, we may collect certain personal
        information from you, such as your name, email address, demographic
        information, and other information relevant to the paid studies for
        which you may be selected. We use this information to select panelists
        for paid studies, to contact you about upcoming studies, and to
        compensate you for your participation.
        <br />
        <br />
        <b>Cookies and Similar Technologies:</b>
        <br />
        We use cookies or similar technologies to persist the state of our
        application and to provide you with a better experience. We do not use
        third-party cookies or cookies to track visits from outside sites.
        <br />
        <br />
        <b>Disclosure of Information:</b>
        <br />
        We may disclose your personal information to third parties in order to
        conduct paid studies, including but not limited to research companies,
        academic institutions, and other organizations. We may also disclose
        your personal information to third parties as required by law or in the
        good faith belief that such action is necessary to comply with legal
        processes, to protect the rights, property, or safety of our company,
        our panelists, or others, or in the event of a merger, acquisition, or
        sale of all or a portion of our assets.
        <br />
        <br />
        <b>Changes to this Privacy Policy:</b>
        <br />
        We reserve the right to modify this Privacy Policy at any time. If we
        make material changes to this Privacy Policy, we will notify you by
        posting the updated Privacy Policy on our website or by sending you an
        email.
        <br />
        <br />
        <b>Contact Us:</b>
        <br />
        If you have any questions or concerns about this Privacy Policy or our
        collection, use, or disclosure of your personal information, please
        contact us at contact@pyxis.ai.
        <br />
      </Typography>
    </Box>
  );
};
export default TermAndConditions;
