export const panelistIdByFirtLastInitialIndex = /* GraphQL */ `
  query PanelistIdByFirtLastInitialIndex(
    $pxFirstLastInitial: String!
    $pxIndex: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegisteredUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    panelistIdByFirtLastInitialIndex(
      pxFirstLastInitial: $pxFirstLastInitial
      pxIndex: $pxIndex
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        
        pxFirstLastInitial
        pxIndex
        
      }
      nextToken
    }
  }
`;