import React, { useState, useEffect, useCallback } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";

import PromptQuestion from "../components/PromptQuestion";
import "../App.css";
import Answers from "../components/Answers";
import { QuestionLabels, QuestionTittleText } from "../assets/css/sxStyles";
import {
  getLocalStorage,
  setLocalStorage,
} from "../Models/data/useLocalStorage";
import { checkIfAnswered, params } from "../common/util";
import { OnlyMedia } from "../common/constant";

const SectionalQuestions = (props) => {
  const [questions, setQuestions] = useState([]);
  const [questExpanded, setQuestExpanded] = useState(0);
  const [questionsList] = useState(props.QuestionsData);
  const [disableIndx, setDisableIndx] = useState(0);
  const [isMediaPlaying, setIsMediaPlaying] = useState(false);
  const [isAnsMediaPlaying, setIsAnsMediaPlaying] = useState(false);

  const [isMediaRecording, setIsMediaRecording] = useState(false);
  const [isAnsDisabled, setIsAnsDisabled] = useState(false);
  const disabledQuestions = getLocalStorage(
    `disabledQuest-${params.id}`
  );

  const [videoIndex, setVideoIndex] = useState(0);

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    var isVideoAvailable = questions.find((e) => e.promptType === "video")
      ? true
      : false;

    if (isVideoAvailable) {
      const videoIds = questions.find((e) => e.promptType === "video").videoIds;
      const vId = videoIds.findIndex((e) => e === params.videoId);
      if (vId != -1) setVideoIndex(vId);
    }
  }, []);

  useEffect(() => {
    let answeredData = getLocalStorage(
      `answers-${params.id}-${props.userData.emailHash}`
    );

    if (answeredData && answeredData.length > 0) {
      let max = answeredData[0].id;
      for (let i = 1; i < answeredData.length; i++)
        if (answeredData[i].id > max) max = answeredData[i].id;

      let dInd = checkNextIndex(max + 1);

      if (dInd - props.start < props.end - props.start + 1)
        setQuestExpanded(dInd - props.start);
      else setQuestExpanded(0);
      setDisableIndx(dInd);
    } else setQuestExpanded(0);

    setQuestions(questionsList.slice(props.start, props.end + 1));
  }, [props.start, props.end, props.userData, setDisableIndx, props.index]);

  const checkNextIndex = useCallback(
    (i) => {
      const data = getLocalStorage(`disabledQuest-${params.id}`);

      if (i < questionsList.length && data.some((item) => item.index === i))
        return checkNextIndex(i + 1);
      else {
        const sectionData = getLocalStorage(
          `disabledSections-${params.id}`
        );

        let sectionsDataList = [];
        sectionData.forEach((element) => {
          let dependentAnswersCount = 0;
          for (let i = element.start; i <= element.end; i++) {
            let isIdPresent = data.some((obj) => obj.index === i);

            if (isIdPresent) dependentAnswersCount++;
          }
          element.isHidden =
            element.end - element.start + 1 === dependentAnswersCount
              ? true
              : false;
          sectionsDataList.push(element);
        });

        setLocalStorage(
          `disabledSections-${params.id}`,
          sectionsDataList
        );
        props.onSectionsListChange(sectionsDataList);
        return i;
      }
    },
    [questions]
  );

  const checkDependancy = useCallback(() => {
    const answeredData = getLocalStorage(
      `answers-${params.id}-${props.userData.emailHash}`
    );
    let dQuestData = disabledQuestions;

    questionsList.forEach((element, index) => {
      const foundObject = answeredData.find(
        (obj) => obj.question === element.dependencies[0]?.question
      );

      if (foundObject) {
        let answerStr = [];
        if (foundObject.type === "rating")
          answerStr.push(foundObject.answer.split("-").slice(1).join("-"));
        else if (foundObject.type === "multi-Select")
          answerStr = foundObject.answer.split(";");
        else answerStr = foundObject.answer;

        if (
          element.dependencies[0].answer.some((elem) =>
            answerStr.includes(elem)
          )
        )
          dQuestData = dQuestData.filter((obj) => obj.title !== element.title);
        else {
          const missingData = element;
          missingData.index = index;
          dQuestData.push(missingData);
        }
      }
    });

    dQuestData = dQuestData.filter(
      (obj, index, self) =>
        index === self.findIndex((o) => o.title === obj.title)
    );
    return dQuestData;
  }, [questions]);

  const handleChange = (question) => (event, isExpanded) =>
    setQuestExpanded(isExpanded ? question : false);

  const handleInputChange = useCallback(
    (e) => {
      const answeredData = getLocalStorage(
        `answers-${params.id}-${props.userData.emailHash}`
      );

      let ind = disableIndx;
      if (answeredData && answeredData.length > 0) {
        let max = answeredData[0].id;
        for (let i = 1; i < answeredData.length; i++)
          if (answeredData[i].id > max) max = answeredData[i].id;
        ind = max + 1;
      }

      let disabledQuestData = checkDependancy();

      setLocalStorage(
        `disabledQuest-${params.id}`,
        disabledQuestData
      );

      const nextIndex = checkNextIndex(e);

      ind = checkNextIndex(ind);
      setDisableIndx(ind);

      if (questions.length <= nextIndex - props.start) {
        setQuestExpanded(-1);
        props.onChange(props.index + 1);
      } else setQuestExpanded(nextIndex - props.start);
    },
    [props.onChange, setDisableIndx, questions, props.start]
  );

  const setMediaChanges = (state) => setIsMediaPlaying(state);

  const setPlayedOnce = (state) => setIsAnsDisabled(!state);

  const setAnsMediaChanges = (state) => setIsAnsMediaPlaying(state);

  const setMediaRecordingChanges = (state) => setIsMediaRecording(state);

  const getQuestionsAnswer = (question) => {
    var ans = "";
    let data = JSON.parse(
      window.localStorage.getItem(
        `answers-${params.id}-${props.userData.emailHash}`
      )
    );

    if (data !== null) {
      const answerListVal = data.find(function (element) {
        return element.question === question.title;
      });

      if (answerListVal != null)
        if (question.answerType === "rating")
          ans = answerListVal.answer.split("-").slice(1).join("-");
        else ans = answerListVal.answer;
    }

    if (ans.length > 0)
      if (props.isMobile)
        return (
          <Typography sx={QuestionLabels}>
            <TaskAltOutlinedIcon sx={{ color: "#1664C0", fontSize: 21 }} />
          </Typography>
        );
      else if (OnlyMedia.includes(question.answerType) || ans.length > 30)
        return <Typography sx={QuestionLabels}>Answered</Typography>;
      else return <Typography sx={QuestionLabels}>{ans}</Typography>;
    else return null;
  };

  return (
    <Grid container rowSpacing={1}>
      {questions.map((ele, i) => {
        if (disabledQuestions.some((q) => q.title === ele.title)) return null;
        else
          return (
            <Grid item xs={12} key={ele.title + i} gap={1}>
              <Accordion
                expanded={questExpanded === i}
                onChange={handleChange(i)}
                disabled={i > disableIndx - props.start ? true : false}
                sx={{
                  maxHeight: "90vh",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={QuestionTittleText}>{ele.title}</Typography>
                  {getQuestionsAnswer(ele)}
                </AccordionSummary>
                <AccordionDetails sx={{ maxHeight: "85vh" }}>
                  {questExpanded === i && (
                    <Grid container rowSpacing={1}>
                      <Grid item xs={12}>
                        <PromptQuestion
                          pType={ele.promptType}
                          quest={ele.question}
                          videoId={videoIndex || 0}
                          index={i}
                          isMediaAnswerPlaying={isAnsMediaPlaying}
                          isAnswered={checkIfAnswered(
                            params.id,
                            i + (props.start || 0)
                          )}
                          isMediaRecording={isMediaRecording}
                          onPlayedOnce={setPlayedOnce}
                          onMediaChange={setMediaChanges}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Answers
                          questionData={ele}
                          index={props.start + i}
                          userData={props.userData}
                          onChange={handleInputChange}
                          answered={
                            i > disableIndx - props.start ? true : false
                          }
                          onAnsMediaPlaying={setAnsMediaChanges}
                          onMediaRecording={setMediaRecordingChanges}
                          isAnsDisabled={isAnsDisabled}
                          isMediaPlaying={isMediaPlaying}
                        />
                      </Grid>
                    </Grid>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
      })}
    </Grid>
  );
};

export default SectionalQuestions;
