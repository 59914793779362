import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import lamejs from 'lamejs';

class MicRecorder {
    constructor(config) {
        this.config = {
            mimeType: 'audio/wav',
            numberOfAudioChannels: 1,
            desiredSampRate: 44100,
            chunkDuration: 60000,
            ...config
        };
        this.recorder = null;
        this.stream = null; // Keep reference to the media stream
        this.mp3Chunks = [];
        this.processedSize = 0;
        this.wavHeaderSize = 44;
    }

    async init() {
        try {
            this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        } catch (error) {
            console.error('Error initializing microphone: ', error);
            throw error;
        }
    }

    async start() {
        if (!this.stream) {
            await this.init(); // Reinitialize the stream if it's not available
        }
        this.mp3Chunks = [];
        this.mp3Worker = new Worker(new URL("./mp3worker.js",import.meta.url))
        this.mp3Worker.onmessage = (e) => {
            this.mp3Chunks.push(e.data.mp3Blob)
            this.processedSize += (e.data.blobSize - this.wavHeaderSize);
        }

        this.processedSize = 0;
        this.recorder = new RecordRTC(this.stream, {
            type: 'audio',
            mimeType: this.config.mimeType,
            recorderType: StereoAudioRecorder,
            numberOfAudioChannels: this.config.numberOfAudioChannels,
            desiredSampRate: this.config.desiredSampRate,
            timeSlice: this.config.chunkDuration,
            ondataavailable: (blob) => {
                //this.processedSize += (blob.size - this.wavHeaderSize);  
                //this.convertToMp3(blob).then(mp3Blob => this.mp3Chunks.push(mp3Blob));
                this.mp3Worker.postMessage(blob)
            }
        });
        this.recorder.startRecording();
    }

 

    stop() {
        return new Promise((resolve, reject) => {
            if (!this.recorder) {
                reject('Recorder not initialized');
                return;
            }
            this.mp3Worker.terminate();
            this.recorder.stopRecording(async () => {
                const fullBlob  = this.recorder.getBlob();
                const finalChunkSize = fullBlob.size - this.processedSize;
                if (finalChunkSize > 0) {
                    const finalChunkBlob = new Blob([
                        fullBlob.slice(0, this.wavHeaderSize), // Header
                        fullBlob.slice(fullBlob.size - finalChunkSize) // Data
                    ]);                    
                    const finalMp3Blob = await this.convertToMp3(finalChunkBlob);
                    this.mp3Chunks.push(finalMp3Blob);
                    resolve(new Blob(this.mp3Chunks,{type:'audio/mp3'}));
                    //const res = await this.convertToMp3(fullBlob);
                    //resolve(res)
                } else {
                    // Resolve with the existing chunks if there's no final chunk
                    resolve(new Blob(this.mp3Chunks,{type:'audio/mp3'}));
                }

                // Release the media stream
                if (this.stream) {
                    this.stream.getTracks().forEach(track => track.stop());
                    this.stream = null;
                }

                // Reset the recorder for the next recording
                this.recorder = null;
            });
        });
    }


    convertToMp3(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function() {
                const buffer = reader.result;
                const wav = lamejs.WavHeader.readHeader(new DataView(buffer));
                console.log("wav",wav,new DataView(buffer),buffer.byteLength)
                const samples = new Int16Array(buffer, wav.dataOffset, (buffer.byteLength-wav.dataOffset) / 2);
                const mp3Encoder = new lamejs.Mp3Encoder(wav.channels, wav.sampleRate, 128);
                const mp3Data = [];
                const mp3Buffer = mp3Encoder.encodeBuffer(samples);

                if (mp3Buffer.length > 0) {
                    mp3Data.push(new Int8Array(mp3Buffer));
                }

                const finalBuffer = mp3Encoder.flush();

                if (finalBuffer.length > 0) {
                    mp3Data.push(new Int8Array(finalBuffer));
                }

                resolve(new Blob(mp3Data, { type: 'audio/mp3' }));
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(blob);
        });
    }
}

export default MicRecorder;
