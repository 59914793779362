import React, { useState, useEffect, useCallback } from "react";

import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  Button,
  FormControlLabel,
  TextField,
  Typography,
  LinearProgress,
} from "@mui/material";

import { Progress, Result } from "antd";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";

// for s3 file upload
import { API, Storage } from "aws-amplify";
import { createResponseUserDetail } from "../graphql/mutations";

import StimulusModal from "../components/stimulusModal";

import {
  clear,
  getLocalStorage,
  removeItem,
  setLocalStorage,
} from "../Models/data/useLocalStorage";

// css
import Styles from "../assets/css/styles";
import {
  QuestionText,
  AnswerText,
  CardContainer,
  Labels,
  MsgLabelJustify,
} from "../assets/css/sxStyles";
import "../App.css";
import "antd/dist/antd.css";
import { params, capitalizeString, checkIfAnswered } from "../common/util";
import { OnlyMedia } from "../common/constant.js";
import PromptQuestion from "../components/PromptQuestion.js";
import VideoCapture from "../Models/VideoCapture.js";
import AudioCapture from "../Models/AudioCapture.js";

const cardStyle = {
  padding: "0 1rem 1em 1rem",
};

const JournalingQuestions = ({
  QuestionsData,
  userData,
  onSignUp,
  completedMsg,
  timeStamp,
}) => {
  const classes = Styles();

  const [isPortrait, setIsPortrait] = useState(
    window.innerWidth < window.innerHeight
  );
  window.onresize = () => {
    if (window.innerWidth < window.innerHeight && !isPortrait)
      setIsPortrait(!isPortrait);
  };
  const [questions] = useState(QuestionsData);

  const [answersList, setAnswersList] = useState([]);

  // answer Type
  const [isVideo, setIsVideo] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  const [isText, setIsText] = useState(false);

  const [isMultiMedia, setIsMultiMedia] = useState(false);
  const [multiMediaValue, setMultiMediaValue] = useState("");

  const [multimediaOptions, setMultimediaOptions] = useState([]);

  const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);

  const [isTesting, setIsTesting] = useState(false);
  const [isStimulusDisabled, setIsStimulusDisabled] = useState(false);

  // Answer option
  const [index, setIndex] = useState(0);

  const [value, setValue] = useState("");
  const [textValue, setTextValue] = useState("");

  // set prev button
  const [isPrev, setIsPrev] = useState(false);
  const [isLast, setIsLast] = useState(true);

  const [isPromptMediaPlaying, setIsPromptMediaPlaying] = useState(false);

  // For all Media
  const [isAnsMediaPlaying, setIsAnsMediaPlaying] = useState(false);
  const [isMediaRecording, setIsMediaRecording] = useState(false);

  const [mediaFileData, setMediaFileData] = useState(null);

  // For progress bar
  const [progress, setProgress] = useState(0);
  const [isProgressBar, setIsProgressBar] = useState(false);

  // for disabling answers
  const [isAnswerDisabled, setIsAnswerDisabled] = useState(true);

  // For open answer
  const [isLastSelectedAnswerOpen, setIsLastSelectedAnswerOpen] =
    useState(false);

  const [isSkippable, setIsSkippable] = useState(false);

  const [isNextEnabled, setIsNextEnabled] = useState(true);
  const [questionsProgress, setQuestionsProgress] = useState(0);
  const [videoIndex, setVideoIndex] = useState(0);

  useEffect(() => {
    setIsSkippable(QuestionsData[index].isSkippable || false);
  }, [index]);

  const setPromptMediaChanges = (state) => setIsPromptMediaPlaying(state);

  const setCaptureMediaChanges = (state) => setIsAnsMediaPlaying(state);

  const setPlayedOnce = (state) => setIsAnswerDisabled(!state);

  const setMediaRecord = (state) => setIsMediaRecording(state);

  const handleMediaData = (data) => setMediaFileData(data);

  useEffect(() => {
    setIsSkippable(QuestionsData[index].isSkippable || false);
  }, [index]);

  useEffect(() => {
    if (progress === 100) {
      setIsProgressBar(false);
      setProgress(0);
    }
  }, [progress]);

  useEffect(() => {
    var isVideoAvailable = questions.find((e) => e.promptType === "video")
      ? true
      : false;

    if (isVideoAvailable) {
      const videoIds = questions.find((e) => e.promptType === "video").videoIds;
      const vId = videoIds.findIndex((e) => e === params.videoId);
      if (vId != -1) {
        setVideoIndex(vId);
      }
    }
  }, [questions]);

  useEffect(() => {
    setIsStimulusDisabled(false);
    if (questions[index].stimulusType)
      if (
        (["video", "audio"].includes(questions[index].promptType) &&
          isMediaRecording) ||
        isPromptMediaPlaying ||
        isAnsMediaPlaying
      )
        setIsStimulusDisabled(true);
  }, [
    isMediaRecording,
    index,
    isPromptMediaPlaying,
    isAnsMediaPlaying,
    questions,
    setIsStimulusDisabled,
  ]);

  useEffect(() => {
    if (isMultiMedia) {
      let medialist = [];

      questions[index].answers.forEach((element) => {
        if (element) medialist.push(element);
      });

      setMultimediaOptions(...[], medialist);
      if (medialist.indexOf("video") > -1) {
        setMultiMediaValue("video");
        setIsVideo(true);
      } else if (medialist.indexOf("audio") > -1) {
        setMultiMediaValue("audio");
        setIsAudio(true);
      } else {
        setMultiMediaValue("text");
        setIsText(true);
      }
    }
  }, [
    index,
    isMultiMedia,
    questions,
    videoIndex,
    setMultimediaOptions,
    setIsAnswerDisabled,
  ]);

  useEffect(() => {
    let data = getLocalStorage(`answers-${params.id}-${userData.emailHash}`);

    if (Object.keys(userData).length === 0) setIsTesting(true);
    else setIsTesting(false);

    let dataindex = 0;

    setIsPrev(false);

    checkForLastQuestion(dataindex);

    if (!(userData.isFinished === true))
      setLocalStorage(`user-${params.id}`, userData);

    setAnswersList(data);

    setAnswerOption(questions[dataindex].answerType);

    if (questions[dataindex].promptType === "text") setIsAnswerDisabled(false);
    else setIsAnswerDisabled(true);
  }, [questions, userData, setIsSurveyCompleted, setIsAnswerDisabled]);

  const checkForLastQuestion = useCallback(
    (id) => {
      if (id < questions.length - 1) setIsLast(false);
      else setIsLast(true);
    },
    [questions, setIsLast]
  );

  const resetMediaOptions = () => {
    setIsPromptMediaPlaying(false);
    setIsAnsMediaPlaying(false);
    setValue("");
    setOptionText("");
    setIsMultiMedia(false);
    setIsLastSelectedAnswerOpen(false);

    setMediaFileData(null);
    setIsStimulusDisabled(false);
  };

  const handleMediaChange = (e) => console.log(e);

  const handleSkip = () => nextQuestion("");

  const nextQuestion = useCallback(
    async (filename) => {
      let answerData = [];
      let valueString = value;

      if (answersList && answersList.length !== 0) {
        answerData = answersList;
        if (answersList.length > index) {
          answersList.forEach((obj) => {
            if (obj.question === questions[index].title)
              obj.answer =
                typeof filename === "string" ? filename : valueString;
          });

          answerData = answersList;
        } else
          answerData.push({
            question: questions[index].title,
            answer:
              typeof filename === "string" || Array.isArray(filename)
                ? filename
                : valueString,
            index,
            type: questions[index].answerType,
          });
      } else
        answerData.push({
          question: questions[index].title,
          answer:
            typeof filename === "string" || Array.isArray(filename)
              ? filename
              : valueString,
          index,
          type: questions[index].answerType,
        });

      setLocalStorage(`answers-${params.id}-${userData.emailHash}`, answerData);
      setAnswersList(...[], answerData);

      if (index !== questions.length - 1) {
        let i = index + 1;
        setIndex(i);
        resetMediaOptions();
        setAnswerOption(questions[i].answerType);
        setPrevButton(i);
        checkForLastQuestion(i);
      }
    },
    [answersList, index, questions, userData, value, isLastSelectedAnswerOpen]
  );

  const onSubmit = useCallback(
    async (filename) => {
      let answerData = [];

      if (answersList)
        answerData = [...answersList.filter((obj) => obj.answer !== "")];

      answerData.push({
        question: questions[index].title,
        answer:
          typeof filename === "string" || Array.isArray(filename)
            ? filename
            : value,
        index: index,
        type: questions[index].answerType,
      });

      let response = {
        firstName: userData.firstName,
        lastInitial: userData.lastInitial,
        emailHash: `${userData.emailHash}-${timeStamp}`,
        surveyID: params.id,
        answers: JSON.stringify(
          answerData.flatMap((a) => {
            return {
              question: a.question,
              answer: a.answer,
              isInS3: OnlyMedia.includes(a.type),
            };
          })
        ),
        journalingDay: timeStamp,
        isFinished: true,
      };

      if (!isTesting) {
        try {
          const createNewResponse = await API.graphql({
            query: createResponseUserDetail,
            variables: { input: response },
            authMode: "AWS_IAM",
          });

          console.log(createNewResponse);
          clear();
          setIsSurveyCompleted(true);
        } catch (error) {
          console.error(error);
        }
      } else {
        clear();
        setIsSurveyCompleted(true);
      }

      setLocalStorage(`${params.id}-user`, userData);
    },
    [answersList, index, questions, userData, value, isTesting]
  );

  const prevQuestion = () => {
    resetMediaOptions();

    let ansD = getLocalStorage(`answers-${params.id}-${userData.emailHash}`);

    let curInd = ansD.findIndex((item) => item.index === index);
    if (curInd === -1) curInd = ansD.length;

    setIsMultiMedia(false);
    setIndex(ansD[curInd - 1].index);
    setPrevButton(ansD[curInd - 1].index);
    if (curInd >= 2) setIsPrev(false);
    setValue(ansD[curInd - 1].answer);

    setAnswerOption(questions[ansD[curInd - 1].index].answerType);
    setIsLastSelectedAnswerOpen(false);
    checkForLastQuestion(ansD[curInd - 1].index);
  };

  const setPrevButton = (value) => setIsPrev(value > 0 ? true : false);

  const setAnswerOption = (val) => {
    setIsAudio(false);
    setIsText(false);
    setIsVideo(false);

    switch (val) {
      case "video":
        setIsVideo(true);
        break;
      case "audio":
        setIsAudio(true);
        break;
      case "text":
        setIsText(true);
        break;
      case "multi-media":
        setIsMultiMedia(true);
        break;

      default:
        break;
    }
  };

  const onAudioSubmit = async () => {
    const newFileName = `${params.id}/${questions[index].title}-${userData.firstName}${userData.lastInitial}-${timeStamp}.mp3`;
    setMediaFileData(null);
    if (!isTesting) {
      try {
        setIsProgressBar(true);
        const result = await Storage.put(newFileName, mediaFileData, {
          contentType: "audio/mp3",
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            setProgress((progress.loaded / progress.total) * 100);
          },
          errorCallback: (err) => {
            console.error("Unexpected error while uploading", err);
          },
        });
        setValue(result.key); //was file.name
        if (!isLast) nextQuestion(result.key);
        else onSubmit(result.key);

        console.log(result);
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    } else {
      setValue(newFileName); //was file.name
      if (!isLast) nextQuestion(newFileName);
      else onSubmit(newFileName);
    }
  };

  const onTextSubmit = async () => {
    const newFileName = `${params.id}/${questions[index].title}-${userData.firstName}${userData.lastInitial}-${timeStamp}.txt`;
    setMediaFileData(null);
    if (!isTesting) {
      try {
        setIsProgressBar(true);
        const result = await Storage.put(newFileName, textValue, {
          contentType: "text/plain", // contentType is optional
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            setProgress((progress.loaded / progress.total) * 100);
          },
          errorCallback: (err) => {
            console.error("Unexpected error while uploading", err);
          },
        });
        console.log(result);
        setValue(result.key); //was file.name
        setTextValue("");
        if (!isLast) nextQuestion(result.key);
        else onSubmit(result.key);

        console.log(result);
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    } else {
      setValue(newFileName); //was file.name
      setTextValue("");
      if (!isLast) nextQuestion(newFileName);
      else onSubmit(newFileName);
    }
  };

  if (isSurveyCompleted) {
    removeItem(`answers-${params.id}-${userData.emailHash}`);
    removeItem(`user-${params.id}`);
    return (
      <div className="app-center">
        <div className="card" style={cardStyle}>
          <Result
            status="success"
            title={
              <Typography sx={QuestionText} align="center">
                Survey Completed
              </Typography>
            }
            subTitle={
              <>
                <Typography sx={MsgLabelJustify}>{completedMsg}</Typography>
                <br />
                {onSignUp && (
                  <Typography sx={MsgLabelJustify}>
                    Are you interested in joining our panelist database for
                    exciting paid study opportunities in the future?
                  </Typography>
                )}
              </>
            }
            extra={
              onSignUp && (
                <Button onClick={onSignUp} type="primary">
                  Sign me up for paid studies!
                </Button>
              )
            }
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="app-center">
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          {!isPortrait && (
            <Grid item xs={2} alignItems="center">
              {isPrev ? (
                <Button
                  className={classes.centeredButton}
                  variant="contained"
                  onClick={prevQuestion}
                >
                  <Typography sx={AnswerText}>Prev</Typography>
                </Button>
              ) : (
                <div></div>
              )}
            </Grid>
          )}
          <Grid item xs="auto">
            <Card sx={CardContainer}>
              <Grid container>
                <Grid item xs={12} paddingRight={"15px"}>
                  <Progress
                    strokeLinecap="butt"
                    strokeColor={{
                      "0%": "#108ee9",
                      "100%": "#87d068",
                    }}
                    percent={questionsProgress}
                  />
                </Grid>
              </Grid>

              <CardContent style={cardStyle}>
                {questions.length > 0 ? (
                  <div className={classes.root}>
                    <Grid container>
                      <Grid item xs={12} padding={0}>
                        <FormControl
                          component="fieldset"
                          style={{ gap: "0.75em" }}
                          fullWidth
                        >
                          <Grid item xs={12} className="prompt-video-container">
                            <Grid item xs={12}>
                              <PromptQuestion
                                pType={questions[index].promptType}
                                quest={questions[index].question}
                                videoId={videoIndex || 0}
                                index={index}
                                isMediaAnswerPlaying={isAnsMediaPlaying}
                                isAnswered={checkIfAnswered(
                                  userData.emailHash,
                                  index
                                )}
                                isMediaRecording={isMediaRecording}
                                onPlayedOnce={setPlayedOnce}
                                onMediaChange={setPromptMediaChanges}
                              />
                            </Grid>
                          </Grid>

                          {questions[index].stimulusType && (
                            <Grid item xs={12}>
                              <StimulusModal
                                type={capitalizeString(
                                  questions[index].stimulusType
                                )}
                                url={questions[index].stimulus}
                                allow={isStimulusDisabled}
                              />
                            </Grid>
                          )}

                          {isMultiMedia && (
                            <RadioGroup
                              aria-label={questions[index].question}
                              name={questions[index].question}
                              value={multiMediaValue}
                            >
                              <Typography sx={Labels}>
                                Please select one option to answer the above
                                question
                              </Typography>
                              <Grid container>
                                {multimediaOptions.map((item, i) => {
                                  return (
                                    <Grid item xs={6} sm={4} key={i}>
                                      <FormControlLabel
                                        value={item.toString().trim()}
                                        control={<Radio />}
                                        disabled={isAnswerDisabled}
                                        label={
                                          <Typography sx={AnswerText}>
                                            {capitalizeString(item.toString())}
                                          </Typography>
                                        }
                                        onChange={(e) => {
                                          setMultiMediaValue(e.target.value);
                                          setAnswerOption(e.target.value);
                                        }}
                                      />
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </RadioGroup>
                          )}

                          {isText && (
                            <Grid item xs={12} width={"100%"}>
                              <TextField
                                id="outlined-multiline-flexible"
                                name={questions[index].question}
                                multiline
                                disabled={isAnswerDisabled}
                                label="Enter you answer"
                                inputProps={{ style: AnswerText }}
                                className="text-area"
                                placeholder="Enter your text here"
                                maxRows={10}
                                value={textValue}
                                onChange={(e) => setTextValue(e.target.value)}
                              />
                            </Grid>
                          )}

                          {isVideo && (
                            <VideoCapture
                              disabled={isAnswerDisabled}
                              onChange={handleMediaChange}
                              data={questions[index].question}
                              isPromptMediaPlaying={isPromptMediaPlaying}
                              onRecording={setMediaRecord}
                              onPlaying={setCaptureMediaChanges}
                              nextEnabled={true}
                              onHandlingData={handleMediaData}
                            />
                          )}

                          {isAudio && (
                            <AudioCapture
                              disabled={isAnswerDisabled}
                              onChange={handleMediaChange}
                              data={questions[index].question}
                              isPromptMediaPlaying={isPromptMediaPlaying}
                              onRecording={setMediaRecord}
                              onPlaying={setCaptureMediaChanges}
                              userData={userData}
                              nextEnabled={true}
                              onHandlingData={handleMediaData}
                            />
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    {isProgressBar && progress > 1 && (
                      <Grid item xs={12} className="progress-bar">
                        <LinearProgress
                          variant="determinate"
                          value={progress}
                        />
                      </Grid>
                    )}
                  </div>
                ) : null}
              </CardContent>

              {isPortrait && (
                <CardActions className={classes.cardActionCustom}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-evenly"
                  >
                    {isPrev ? (
                      <Button variant="contained" onClick={prevQuestion}>
                        <Typography sx={AnswerText}>Prev</Typography>
                      </Button>
                    ) : (
                      <div></div>
                    )}

                    {!isLast ? (
                      <Grid item>
                        {isSkippable && isNextEnabled ? (
                          <Button
                            variant="contained"
                            endIcon={<KeyboardDoubleArrowRightOutlinedIcon />}
                            onClick={handleSkip}
                          >
                            <Typography sx={AnswerText}>Skip</Typography>
                          </Button>
                        ) : (
                          <Grid item>
                            <Button
                              variant="contained"
                              onClick={() => {
                                if (isVideo) handleDownload();
                                else if (isAudio) onAudioSubmit();
                                else onTextSubmit();
                              }}
                              disabled={
                                mediaFileData !== null || textValue.length > 0
                                  ? false
                                  : true
                              }
                            >
                              <Typography sx={AnswerText}>Next</Typography>
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      <div>
                        <Button
                          variant="contained"
                          onClick={() => {
                            if (isVideo) handleDownload();
                            else if (isAudio) onAudioSubmit();
                            else onTextSubmit();
                          }}
                          disabled={
                            mediaFileData !== null || textValue.length > 0
                              ? false
                              : true
                          }
                        >
                          <Typography sx={AnswerText}>Submit</Typography>
                        </Button>
                      </div>
                    )}
                  </Grid>
                </CardActions>
              )}
            </Card>
          </Grid>
          {!isPortrait && (
            <Grid item xs={2}>
              {!isLast ? (
                <Grid item>
                  {isSkippable && isNextEnabled ? (
                    <Button
                      className={classes.centeredButton}
                      variant="contained"
                      endIcon={
                        <KeyboardDoubleArrowRightOutlinedIcon
                          style={{ fontSize: 20 }}
                        />
                      }
                      onClick={handleSkip}
                    >
                      <Typography sx={AnswerText}>Skip</Typography>{" "}
                    </Button>
                  ) : (
                    <Grid item>
                      <Button
                        className={classes.centeredButton}
                        variant="contained"
                        onClick={() => {
                          if (isVideo) handleDownload();
                          else if (isAudio) onAudioSubmit();
                          else onTextSubmit();
                        }}
                        disabled={
                          mediaFileData !== null ||
                          isProgressBar ||
                          textValue.length > 0
                            ? false
                            : true
                        }
                      >
                        <Typography sx={AnswerText}>Next</Typography>
                      </Button>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <div>
                  <Button
                    className={classes.centeredButton}
                    variant="contained"
                    onClick={() => {
                      if (isVideo) handleDownload();
                      else if (isAudio) onAudioSubmit();
                      else onTextSubmit();
                    }}
                    disabled={
                      mediaFileData !== null ||
                      isProgressBar ||
                      textValue.length > 0
                        ? false
                        : true
                    }
                  >
                    <Typography sx={AnswerText}>Submit</Typography>
                  </Button>
                </div>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
};

export default JournalingQuestions;
