export const Screen_Out_Message =
  "We wanted to let you know that your screening for this process has been completed. If you have any questions or need further assistance, please don't hesitate to contact our support team at pyxis.ai.";

export const Optional_Message = ""
  //"Payment of $15 in the form of an Amazon gift card is sent to the email you provide within 1-3 days after successfully completing the study.";

export const Closed_Message =
  "Thank you for your interest in our survey. We have closed the study and are no longer accepting responses. We appreciate your participation and value your feedback.";

export const Completed_Message =
  "Thank you for completing the survey. Your feedback is valuable to us and helps us improve our services. We appreciate your time and participation.";

export const OnlyMedia = ["audio", "video", "text", "multi-media","interview"];
