import React, { useState, useEffect, useRef, useCallback } from "react";

import { Grid, Button, IconButton, Typography } from "@mui/material";

import Webcam from "react-webcam";
import ReactPlayer from "react-player/lazy";

// for icons
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import FlipCameraAndroidRoundedIcon from "@mui/icons-material/FlipCameraAndroidRounded";

// Amplify upload
import { Storage } from "aws-amplify";

// css
import { AnswerText, NoteText } from "../assets/css/sxStyles";
import "../App.css";
import "antd/dist/antd.css";
import { Progress } from "antd";
import { params } from "../common/util";

const VideoCapture = (props) => {
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [videoFilePath, setVideoFilePath] = useState(null);
  const [isTesting, setIsTesting] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  // For progress bar
  const [progress, setProgress] = useState(0);
  const [isProgressBar, setIsProgressBar] = useState(false);
  const [isFrontFaceOn, setFrontFaceOn] = useState(true);
  const [videoConstraints, setVideoConstraints] = useState(null);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isAppleDevice = /iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent);

  useEffect(() => {
    if (isAppleDevice)
      setVideoConstraints({
        facingMode: isFrontFaceOn ? "user" : "environment",
        video: {
          mimeType: "video/mp4",
          audioBitsPerSecond: 190000,
          videoBitsPerSecond: 1000000,
        },
      });
    else
      setVideoConstraints({
        facingMode: isFrontFaceOn ? "user" : "environment",
        video: {
          mimeType: "video/mp4",
        },
      });
  }, [isFrontFaceOn]);

  useEffect(() => {
    if (props.userData === undefined) setIsTesting(true);
    else setIsTesting(false);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setIsProgressBar(false);
      setProgress(0);
    }
  }, [progress]);

  useEffect(() => {
    if (props.isPromptMediaPlaying) setIsPlaying(false);
  }, [props.isPromptMediaPlaying]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        if (props.nextEnabled) props.onHandlingData(data);
        setRecordedChunks((prev) => prev.concat(data));

        const video = new Blob([data], {
          type: "video/mp4",
        });
        const url = window.URL.createObjectURL(video);
        setVideoFilePath(url);
        console.log(url);
      }
      props.onRecording(false);
    },
    [setRecordedChunks, setVideoFilePath]
  );

  const onStartPlaying = () => {
    setIsPlaying(true);
    props.onPlaying(true);
  };

  const onStopPlaying = () => {
    setIsPlaying(false);
    props.onPlaying(false);
  };

  const handleStartCaptureClick = useCallback(() => {
    props.onRecording(true);
    setCapturing(true);

    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream);

    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    props.onRecording(false);
    setCapturing(false);
    mediaRecorderRef.current.stop();
  }, [mediaRecorderRef, setCapturing]);

  const handleSubmit = useCallback(
    async (event) => {
      props.onRecording(false);
      const userData = props.userData;
      let newFileName = `${params.id}/${props.data.title}-${userData.firstName}${userData.lastInitial}.mp4`;

      const file = {
        // `uri` can also be a file system path (i.e. file://)
        uri: new File(recordedChunks, newFileName),
        name: newFileName,
        type: "video/mp4",
      };

      if (!isTesting) {
        try {
          setIsProgressBar(true);
          const result = await Storage.put(file.name, file.uri, {
            contentType: "video/mp4", // contentType is optional
            progressCallback(progress) {
              setProgress(
                ((progress.loaded / progress.total) * 100).toFixed(0)
              );
            },
            errorCallback: (err) => {
              console.error("Unexpected error while uploading", err);
            },
          });
          newFileName = result.key;
          setRecordedChunks([]);
        } catch (error) {
          console.error("Error uploading file: ", error);
        }
      } else setRecordedChunks([]);

      event.target = {
        name: props.data.question,
        value: newFileName,
      };
      props.onChange(event);
    },
    [recordedChunks, isTesting]
  );

  return (
    <Grid item xs={12} className="answer-container">
      {recordedChunks.length > 0 ? (
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          rowSpacing={2}
        >
          <Grid item xs={12} height={"100%"} className="prompt-video-container">
            <ReactPlayer
              controls
              playsinline={true}
              playing={isPlaying}
              onPlay={onStartPlaying}
              onPause={onStopPlaying}
              onEnded={onStopPlaying}
              width={
                (isPlaying || !props.disabled) && !props.isPromptMediaPlaying
                  ? "100%"
                  : "60%"
              }
              height={
                (isPlaying || !props.disabled) && !props.isPromptMediaPlaying
                  ? "100%"
                  : "60%"
              }
              className="video"
              url={videoFilePath}
            />
          </Grid>

          <Grid item xs={12} width={"100%"}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs="auto">
                <Button
                  variant="outlined"
                  color="success"
                  startIcon={<AutorenewOutlinedIcon style={{ fontSize: 20 }} />}
                  size="small"
                  onClick={() => {
                    setRecordedChunks([]);
                    props.onRecording(false);
                    if (props.nextEnabled) props.onHandlingData(null);
                  }}
                  disabled={isProgressBar}
                >
                  <Typography sx={AnswerText}>
                    Re{`${String.fromCodePoint(8209)}`}do
                  </Typography>
                </Button>
              </Grid>

              <Grid item xs="auto">
                {isProgressBar ? (
                  <Progress
                    type="circle"
                    percent={progress}
                    width={50}
                    strokeWidth={5}
                    strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
                  />
                ) : (
                  <Grid item xs="auto">
                    {!props.nextEnabled && (
                      <Button
                        variant="outlined"
                        startIcon={
                          <BackupOutlinedIcon style={{ fontSize: 20 }} />
                        }
                        size="small"
                        disabled={isProgressBar}
                        onClick={handleSubmit}
                      >
                        <Typography sx={AnswerText}>Submit</Typography>{" "}
                      </Button>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography sx={NoteText}>
              Note: Click on {!props.isLast ? "next" : "submit"} to upload the
              recording
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          rowSpacing={2}
        >
          <Grid
            item
            xs={12}
            flexDirection={"column"}
            className="prompt-video-container"
          >
            <Webcam
              audio={true}
              muted={true}
              ref={webcamRef}
              width={!props.disabled ? "100%" : "60%"}
              className="video"
              videoConstraints={videoConstraints}
            />
            {isMobile && (
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                mt={-5}
                bgcolor={"#0000004f"}
              >
                <IconButton
                  aria-label="reverse cam"
                  onClick={() => {
                    setFrontFaceOn(!isFrontFaceOn);
                  }}
                >
                  <FlipCameraAndroidRoundedIcon
                    style={{ color: "white" }}
                    fontSize="1.5em"
                  />
                </IconButton>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            {capturing ? (
              <Button
                variant="outlined"
                color="error"
                onClick={handleStopCaptureClick}
                startIcon={<StopCircleOutlinedIcon style={{ fontSize: 20 }} />}
                size="small"
              >
                <Typography sx={AnswerText}>Stop</Typography>
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="success"
                startIcon={
                  <RadioButtonCheckedOutlinedIcon style={{ fontSize: 20 }} />
                }
                size="small"
                onClick={handleStartCaptureClick}
                disabled={props.disabled}
              >
                <Typography sx={AnswerText}>Record</Typography>
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography sx={NoteText}>
              Note: Click on record to start recording
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default VideoCapture;
