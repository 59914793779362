import { useRef, useEffect,useState } from 'react';

const AudioVisualizer = ({ canvasRef, stream}) => {
    const requestIDRef = useRef(null);
    const audioContextRef = useRef(null)
    const analyserRef = useRef(null)

    useEffect(()=>{
        audioContextRef.current = new AudioContext();
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 256
        const source = audioContextRef.current.createMediaStreamSource(stream);
        source.connect(analyserRef.current);
        requestIDRef.current = requestAnimationFrame(animate)
        return () => {cancelAnimationFrame(requestIDRef.current)}
    }
    ,[])

    //const currentRMSRef = useRef(rms);
    //const previousRMSRef = useRef([0,0,0,0,0,0,0])
    //const currentTick = useRef(Date.now())



    const drawBars = (rmsValue, ts) => {
        //const now = Date.now()
        //const easeOut = easeInOutQuad((now - ts)/1000.0)
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        const width = canvas.width;
        const height = canvas.height;
        const barWidth = width / 15; // Make the bars a bit thinner to compensate for the rounded ends
        const maxBarHeight = height * 0.8; // Use 80% of height to ensure bars stay within canvas
      
        // Set the background to black
        ctx.fillStyle = '#000000';
        ctx.fillRect(0, 0, width, height);
      
        // Set the bar color to white and configure line style
        ctx.strokeStyle = '#FFFFFF';
        ctx.lineWidth = barWidth;
        ctx.lineCap = 'round'; // This will create the rounded effect on the bars
      
        const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
        analyserRef.current.getByteFrequencyData(dataArray);

        // Use the audio data to update your animation
        // Example: Draw something simple based on the volume
        const vol = [0,0,0,0,0,0,0]
        const volCount = [0,0,0,0,0,0,0]
        for (let i=1; i<dataArray.length; i++){
          //const j = Math.floor(i/(128.0/7))
          const j = Math.floor(Math.log2(i))
          vol[j]+=dataArray[i]
          volCount[j] += 1
        }


        for (let i = 0; i < 7; i++) {
          //const easeOut = easeInOutQuad(((now - ts))/1000.0)
          //const barHeight = ((1-easeOut) * previousRMSRef.current[i] +  easeOut * rmsValue[i]) * maxBarHeight
          const barHeight = (vol[i]/volCount[i])* maxBarHeight /255
          const barX = (2 * (6-i) + 1.5) * barWidth; // Adjust the position to fit the bars within the canvas
      
          // Drawing the line for the bar
          ctx.beginPath();
          ctx.moveTo(barX, height - (height - barHeight) / 2); // Move to the start of the bar
          ctx.lineTo(barX, (height - barHeight) / 2); // Draw the line to the end of the bar
          ctx.stroke();
        }
      };
         
    
     /* function easeInOutQuad(t) {
          return t>1?1-t:t
        //return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      }*/

    const animate = () => {
        //drawBars(currentRMSRef.current,currentTick.current );
        drawBars()
        requestIDRef.current = requestAnimationFrame(animate);
    };

    /*useEffect(() => {
        requestIDRef.current = requestAnimationFrame(animate);

        return () => {
            cancelAnimationFrame(requestIDRef.current);
        };
    }, []);*/

  /*  useEffect(() => {
        console.log("rmss",rms)
        previousRMSRef.current = currentRMSRef.current
        currentRMSRef.current = [0.3*rms, 0.7*rms, 0.9*rms, rms, 0.9*rms, 0.7*rms, 0.3*rms]


        //new Array(7)
        //const randomFactor = Math.random() * 0.4 + 0.9
        //for (let i = 0; i < 7; i++) {

            //const randomFactor = Math.random() * 0.4 + 0.9
            //currentRMSRef.current[i] = Math.max(0, rms * randomFactor)
        //}
        currentTick.current = Date.now()

        //drawBars(rms);
    }, [rms]);*/

    return null;
};

export default AudioVisualizer;
