import React, { useState, useEffect, useRef, useCallback } from "react";

import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  Checkbox,
  Button,
  FormControlLabel,
  TextField,
  Rating,
  Typography,
  FormGroup,
  LinearProgress,
} from "@mui/material";

// for s3 file upload
import { API, Storage } from "aws-amplify";
import { updateResponseUserDetail } from "../graphql/mutations";

// for camera
import Webcam from "react-webcam";
import ReactPlayer from "react-player/lazy";

// for audio recording
import AudioPlayer from "material-ui-audio-player";
//import MicRecorder from "mic-recorder-to-mp3";
import MicRecorder from "../components/MicRecorder";

// for icons
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import { StarRate } from "@material-ui/icons";

// css
import Styles from "./../assets/css/styles";
import {
  HeaderText2,
  QuestionText,
  AnswerText,
  CardContainer,
  Labels,
  NoteText,
  GridContainer,
} from "./../assets/css/sxStyles";
import "../App.css";
import CountDownTimer from "../components/CountDownTimer";
import { OnlyMedia } from "../common/constant";

const Questions = ({ QuestionsData, userData, onSignUp }) => {
  const classes = Styles();

  const [isPortrait, setIsPortrait] = useState(
    window.innerWidth < window.innerHeight
  );
  window.onresize = () => {
    if (window.innerWidth < window.innerHeight && !isPortrait)
      setIsPortrait(!isPortrait);
  };
  const [questions] = useState(QuestionsData);
  const [answersList, setAnswersList] = useState([]);
  const [optionalAnswers, setOptionalAnswers] = useState([]);

  // answer Type
  const [isVideo, setIsVideo] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  const [isText, setIsText] = useState(false);
  const [isRadio, setIsRadio] = useState(false);
  const [isCheckbox, setIsCheckbox] = useState(false);
  const [isRating, setIsRating] = useState(false);
  const [isMultiMedia, setIsMultiMedia] = useState(false);
  const [multiMediaValue, setMultiMediaValue] = useState("");

  // for rating hover labels
  const [hover, setHover] = useState(-1);
  const [precision, setPrecision] = useState(1);
  const [labels, setLabels] = useState({});
  const [ratingValue, setRatingValue] = useState("");

  const [isSurveyCompleted, setIsSurveyCompleted] = useState(
    userData.isFinished ? true : false
  );

  const [isTesting, setIsTesting] = useState(false);

  // Answer option
  const [index, setIndex] = useState(0);
  const [value, setValue] = useState("");
  const [textValue, setTextValue] = useState("");

  // set prev button
  const [isPrev, setIsPrev] = useState(false);
  const [isLast, setIsLast] = useState(true);
  const [lastIndex, setLastIndex] = useState("");

  // camera
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [videoFilePath, setVideoFilePath] = useState(null);
  const [isPreviewPlaying, setIsPreviewPlaying] = useState(false);

  // Audio
  const [audioFile, setAudioFile] = useState();
  const [blobURL, setBlobUrl] = useState(null);
  const [isAudioRecording, setIsAudioRecording] = useState(false);
  const audioMimeType = "audio/mp3";
  const recorder = useRef(null); //Recorder

  // timer for recording audio
  const hoursMinSecs = { hours: 0, minutes: 0, seconds: 0 };

  // For progress bar
  const [progress] = useState(0);
  const [isProgressBar] = useState(false);

  useEffect(() => {
    if (isMultiMedia) {
      let medialist = questions[index].answers[0].answerText
        .split(",")
        .map((element) => {
          return element.trim();
        });

      if (medialist.indexOf("video") > -1) {
        setMultiMediaValue("video");
        setIsVideo(true);
      } else if (medialist.indexOf("audio") > -1) {
        setMultiMediaValue("audio");
        setIsAudio(true);
      } else {
        setMultiMediaValue("text");
        setIsText(true);
      }
    }
  }, [index, isMultiMedia, questions]);

  const setRatingOption = useCallback((data) => {
    let div = 1;
    if (data.range === "1-10") {
      div = 2;
      setPrecision(0.5);
    }

    var labelOption = {};

    if (data.answers) {
      for (let i = 0; i < data.answers.length - 1; i++) {
        let text = strToPascalCase(data.answers[i].answerText);
        labelOption[(i + 1) / div] = text;
      }
    }
    setLabels(labelOption);
  }, []);

  const handleChange = (event) => {
    // Add contidtion for submit button
    if (!isRating && !isText) {
      const curIndex = questions.find(
        (x) => x.question === event.target.name
      ).id;

      if (lastIndex === curIndex) {
        var isAnswerDependent = optionalAnswers.find(
          (x) => x.answerText === event.target.value
        ).answerDependency;
        if (isAnswerDependent) {
          setIsLast(false);
        } else {
          setIsLast(true);
        }
      }
    }

    if (isCheckbox) {
      let checkedArray = [];
      if (value !== "") {
        checkedArray = value.split(";");
      }
      if (event.target.checked) {
        checkedArray.push(event.target.value);
      } else {
        var index = checkedArray.indexOf(event.target.value);
        checkedArray.splice(index, 1);
      }

      // setValue(checkedArray.toString());
      setValue(checkedArray.join(";"));
    } else if (isRating) {
      setRatingValue(event.target.value);
      if (labels[event.target.value] !== undefined) {
        setValue(event.target.value + "-" + labels[event.target.value]);
      } else {
        setValue(event.target.value);
      }
    } else {
      setValue(event.target.value);
    }
  };

  useEffect(() => {
    let data = JSON.parse(
      window.localStorage.getItem(`answers-${userData.surveyID}`)
    );

    console.log(userData);
    console.log(questions);

    console.log(QuestionText, AnswerText);

    if (Object.keys(userData).length === 0) {
      setIsTesting(true);
    } else {
      setIsTesting(false);
    }

    let dataindex = 0;

    if (data !== null) {
      setIndex(data.length);
      setIsPrev(true);
      dataindex = data.length;
      setIsSurveyCompleted(userData.isFinished);
      console.log(data);
    }

    dataindex = checkDependancy(dataindex, "add");
    checkForLastQuestion(dataindex + 1);

    if (!(userData.isFinished === true))
      window.localStorage.setItem(
        `user-${userData.surveyID}`,
        JSON.stringify(userData)
      );

    if (userData.answers !== null) {
      setAnswersList(...[], data);
    }
    setAnswerOption(questions[dataindex].answerTypeSelected);
    setOptionalAnswers(...[], questions[dataindex].answers);

    if (questions[dataindex].answerTypeSelected === "rating") {
      setRatingOption(questions[dataindex]);
    }
  }, [questions, userData, setPrecision, setRatingOption]);

  const checkForLastQuestion = useCallback(
    (index) => {
      const i = checkDependancy(index, "add");
      if (i > questions.length - 1) {
        setIsLast(true);
        setLastIndex(index - 1);
      } else {
        setIsLast(false);
        setLastIndex(-1);
      }
    },
    [questions, setIsLast]
  );

  const checkDependancy = useCallback(
    (i, val) => {
      if (i < questions.length) {
        if (questions[i].dependency) {
          const dependentData = questions[i].dependencyData;

          let dependantAnswer = value;

          if (val === "sub") {
            dependantAnswer = answersList[dependentData.questionId - 1].answer;
          }

          if (dependentData.answerText === dependantAnswer) {
            return i;
          } else {
            if (val === "add") {
              return checkDependancy(i + 1, "add");
            } else {
              return checkDependancy(i - 1, "sub");
            }
          }
        } else {
          return i;
        }
      } else {
        return i;
      }
    },
    [answersList, questions, value]
  );

  const nextQuestion = useCallback(
    async (filename) => {
      let answerData = [];

      if (answersList) {
        answerData = answersList;

        if (answersList.length > index) {
          for (const obj of answersList) {
            if (obj.question === index) {
              obj.answer = typeof filename === "string" ? filename : value;
              break;
            }
          }
          answerData = answersList;
        } else {
          answerData.push({
            question: index,
            answer: typeof filename === "string" ? filename : value,
          });
        }
      } else {
        answerData.push({
          question: index,
          answer: typeof filename === "string" ? filename : value,
        });
      }

      window.localStorage.setItem(
        `answers-${userData.surveyID}`,
        JSON.stringify(answerData)
      );
      setAnswersList(...[], answerData);
      setValue("");
      setVideoFilePath(null);
      setIsMultiMedia(false);
      setRatingValue("");

      // reset Audio
      setBlobUrl(null);
      setIsAudioRecording(false);

      if (index !== questions.length - 1) {
        let i = checkDependancy(index + 1, "add");
        setIndex(i);
        setAnswerOption(questions[i].answerTypeSelected);
        setPrevButton(i);
        checkForLastQuestion(i + 1);
        setOptionalAnswers(...[], questions[i].answers);
        if (questions[i].answerTypeSelected === "rating") {
          console.log("Hello :::: ", questions[i]);
          setRatingOption(questions[i]);
        }
      }
    },
    [
      answersList,
      index,
      questions,
      userData,
      value,
      checkDependancy,
      setRatingOption,
    ]
  );

  const onSubmit = useCallback(
    async (filename) => {
      let answerData = [];

      if (answersList) {
        answerData = [...answersList];
      }

      answerData.push({
        question: index,
        answer: typeof filename === "string" ? filename : value,
      });

      let response = {
        emailHash: userData.emailHash,
        surveyID: userData.surveyID,
        answers: JSON.stringify(
          answerData.map((a) => {
            return {
              question: questions[a.question].questionTitle,
              answer: a.answer,
              isInS3: OnlyMedia.includes(
                questions[a.question].answerTypeSelected
              ),
            };
          })
        ),
        isFinished: true,
      };

      if (!isTesting) {
        try {
          const updateResponse = await API.graphql({
            query: updateResponseUserDetail,
            variables: { input: response },
            authMode: "AWS_IAM",
          });

          console.log(updateResponse);

          window.localStorage.clear();
          setIsSurveyCompleted(true);
        } catch (error) {
          console.error(error);
        }
      } else {
        window.localStorage.clear();
        setIsSurveyCompleted(true);
      }
    },
    [answersList, index, questions, userData, value, isTesting]
  );

  const prevQuestion = () => {
    let i = checkDependancy(index - 1, "sub");
    setIsMultiMedia(false);
    setIndex(i);
    setPrevButton(i);
    setAnswerOption(questions[i].answerTypeSelected);
    setOptionalAnswers(...[], questions[i].answers);
    if (questions[i].answerTypeSelected === "rating") {
      setRatingOption(questions[i]);
    }

    checkForLastQuestion(i + 1);
  };

  const setPrevButton = (value) => {
    if (value > 0) {
      setIsPrev(true);
    } else {
      setIsPrev(false);
    }
  };

  const setAnswerOption = (val) => {
    setIsCheckbox(false);
    setIsAudio(false);
    setIsRadio(false);
    setIsText(false);
    setIsVideo(false);
    setIsRating(false);

    switch (val) {
      case "video":
        setIsVideo(true);
        break;
      case "audio":
        setIsAudio(true);
        break;
      case "text":
        setIsText(true);
        break;
      case "single-Select":
        setIsRadio(true);
        break;
      case "multi-Select":
        setIsCheckbox(true);
        break;
      case "rating":
        setIsRating(true);
        break;
      case "multi-media":
        setIsMultiMedia(true);
        console.log("multiselect options : ", questions[index + 1]);
        break;
      default:
        break;
    }
  };

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));

        const video = new Blob([data], {
          type: "video/mp4",
        });
        const url = window.URL.createObjectURL(video);
        setVideoFilePath(url);
        console.log(url);
      }
    },
    [setRecordedChunks, setVideoFilePath]
  );

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    console.log(webcamRef);

    var options = { mimeType: "video/mp4" };
    var iphoneOptions = {
      mimeType: "video/mp4",
      audioBitsPerSecond: 190000,
      videoBitsPerSecond: 1000000,
    };

    var isChrome = navigator.userAgent.indexOf("Chrome") != -1;

    var isFirefox = typeof InstallTrigger !== "undefined";
    if (
      /(iPad|iPhone|iPod|Macintosh)/g.test(navigator.userAgent) &&
      !isChrome &&
      !isFirefox
    ) {
      mediaRecorderRef.current = new MediaRecorder(
        webcamRef.current.stream,
        iphoneOptions
      );
    } else {
      // alert(?Android : ?, options);
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        options,
      });
    }

    console.log(mediaRecorderRef);

    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    setCapturing(false);
    setIsPreviewPlaying(false);
    mediaRecorderRef.current.stop();
  }, [mediaRecorderRef, setCapturing]);

  const handleDownload = useCallback(async () => {
    const newFileName = `${userData.surveyID}/${questions[index].questionTitle}-${userData.firstName}${userData.lastInitial}`;

    console.log(recordedChunks);

    const file = {
      // `uri` can also be a file system path (i.e. file://)
      uri: new File(recordedChunks, newFileName),
      name: newFileName + ".mp4",
      type: "video/mp4",
    };

    console.log(file);

    if (!isTesting) {
      try {
        const result = await Storage.put(file.name, file.uri, {
          contentType: "video/mp4", // contentType is optional
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
          },
          errorCallback: (err) => {
            console.error("Unexpected error while uploading", err);
          },
        });
        console.log(result);
        setRecordedChunks([]);
        setValue(result.key); //was file.name.
        if (index !== questions.length - 1) {
          nextQuestion(result.key);
        } else {
          onSubmit(result.key);
        }
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    } else {
      setRecordedChunks([]);
      setValue(file.name); //was file.name.
      if (index !== questions.length - 1) {
        nextQuestion(file.name);
      } else {
        onSubmit(file.name);
      }
    }
  }, [
    recordedChunks,
    index,
    questions,
    userData,
    nextQuestion,
    onSubmit,
    isTesting,
  ]);

  // Audio recorder
  useEffect(() => {
    const initializeRecorder = async () => {
      try {
        await recorder.current.init();
      } catch (error) {
        console.error("Error initializing recorder: ", error);
        // Handle the error appropriately
      }
    };
    //Declares the recorder object and stores it in ref
    recorder.current = new MicRecorder({ bitRate: 128 });
    initializeRecorder();
  }, []);

  const startRecording = () => {
    recorder.current.start();
    setIsAudioRecording(true);
  };

  const stopRecording = () => {
    setIsAudioRecording(false);
    recorder.current
      .stop()
      .then((blob) => {
        const newBlobUrl = URL.createObjectURL(blob); //generates url from blob
        setBlobUrl(newBlobUrl); //refreshes the page
        const newFileName = `${userData.surveyID}/${questions[index].questionTitle}-${userData.firstName}${userData.lastInitial}.mp3-`;
        setAudioFile(blobToFile(blob, newFileName));
      })
      .catch((e) => console.log(e));
  };

  function blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  const onAudioSubmit = async () => {
    const newFileName = `${userData.surveyID}/${questions[index].questionTitle}-${userData.firstName}${userData.lastInitial}.mp3`;

    if (!isTesting) {
      try {
        const result = await Storage.put(newFileName, audioFile, {
          contentType: audioMimeType, // contentType is optional
        });
        setValue(result.key); //was file.name
        if (index !== questions.length - 1) {
          nextQuestion(result.key);
        } else {
          onSubmit(result.key);
        }
        console.log(result);
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    } else {
      setValue(newFileName); //was file.name
      if (index !== questions.length - 1) {
        nextQuestion(newFileName);
      } else {
        onSubmit(newFileName);
      }
    }
  };

  const onTextSubmit = async () => {
    const newFileName = `${userData.surveyID}/${questions[index].questionTitle}-${userData.firstName}${userData.lastInitial}.txt`;

    if (!isTesting) {
      try {
        const result = await Storage.put(newFileName, textValue, {
          contentType: "text/plain", // contentType is optional
        });
        console.log(result);
        setValue(result.key); //was file.name
        setTextValue("");
        if (index !== questions.length - 1) {
          nextQuestion(result.key);
        } else {
          onSubmit(result.key);
        }
        console.log(result);
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    } else {
      setValue(newFileName); //was file.name
      setTextValue("");
      if (index !== questions.length - 1) {
        nextQuestion(newFileName);
      } else {
        onSubmit(newFileName);
      }
    }
  };

  const strToPascalCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  if (isSurveyCompleted) {
    window.localStorage.removeItem(`answers-${userData.surveyID}`);
    window.localStorage.removeItem(`user-${userData.surveyID}`);
    return (
      <div className="card">
        <div className="container">
          <Typography sx={HeaderText2}>
            Finished! Thank you for completing the survey.
          </Typography>
          {onSignUp && (
            <div style={{ width: "100%" }}>
              <Typography sx={HeaderText2}>
                Would you like to register in our database of panelists for
                future opportunities to be recruited in paid studies?
              </Typography>
              <Typography sx={{ m: 1 }}>&nbsp;</Typography>
              <Button
                className={classes.centeredButton}
                variant="contained"
                onClick={onSignUp}
              >
                <Typography sx={AnswerText}>
                  Register me for future paid studies opportunity
                </Typography>
              </Button>
              <Typography sx={{ m: 1 }}>&nbsp;</Typography>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        {!isPortrait && (
          <Grid item xs={2} alignItems="center">
            {isPrev ? (
              <Button
                className={classes.centeredButton}
                variant="contained"
                onClick={prevQuestion}
              >
                <Typography sx={AnswerText}>Prev</Typography>
              </Button>
            ) : (
              <div></div>
            )}
          </Grid>
        )}
        <Grid item xs="auto">
          <Card sx={CardContainer}>
            <CardContent>
              {questions.length > 0 ? (
                <div className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <Typography sx={QuestionText}>
                          {strToPascalCase(
                            questions[index].question.toString()
                          )}
                        </Typography>

                        <br />

                        {isMultiMedia && (
                          <RadioGroup
                            aria-label={questions[index].question}
                            name={questions[index].question}
                            value={multiMediaValue}
                          >
                            <Typography sx={Labels}>
                              Please select one option to answer the above
                              question
                            </Typography>
                            <Grid container>
                              {questions[index].answers[0].answerText
                                .split(",")
                                .map((item, i) => {
                                  return (
                                    <Grid item xs={6} sm={4} key={i}>
                                      <FormControlLabel
                                        value={item.toString().trim()}
                                        control={<Radio />}
                                        label={
                                          <Typography sx={AnswerText}>
                                            {strToPascalCase(
                                              item.toString().trim()
                                            )}
                                          </Typography>
                                        }
                                        onChange={(e) => {
                                          setMultiMediaValue(e.target.value);
                                          setAnswerOption(e.target.value);
                                        }}
                                      />
                                    </Grid>
                                  );
                                })}
                            </Grid>
                            <br />
                          </RadioGroup>
                        )}

                        {isRadio && (
                          <RadioGroup
                            aria-label={questions[index].question}
                            name={questions[index].question}
                            value={value}
                          >
                            {questions[index].answers.map((item, i) => {
                              return (
                                <FormControlLabel
                                  value={item.answerText}
                                  control={<Radio />}
                                  label={
                                    <Typography sx={AnswerText}>
                                      {strToPascalCase(item.answerText)}
                                    </Typography>
                                  }
                                  onChange={handleChange}
                                  key={i}
                                />
                              );
                            })}
                          </RadioGroup>
                        )}

                        {isText && (
                          <Grid item xs={12} width={"100%"}>
                            <TextField
                              id="outlined-multiline-flexible"
                              name={questions[index].question}
                              multiline
                              inputProps={{ style: AnswerText }}
                              className="text-area"
                              placeholder="Enter your text here"
                              maxRows={10}
                              value={textValue}
                              onChange={(e) => setTextValue(e.target.value)}
                            />
                          </Grid>
                        )}

                        {isRating && (
                          <Grid container>
                            <Grid item xs={12}>
                              <Rating
                                name={questions[index].question}
                                value={ratingValue}
                                precision={precision}
                                size="large"
                                max={5}
                                onChange={handleChange}
                                onChangeActive={(event, newHover) => {
                                  setHover(newHover);
                                }}
                                emptyIcon={
                                  <StarRate
                                    style={{ opacity: 0.55 }}
                                    fontSize="inherit"
                                  />
                                }
                              />
                            </Grid>
                            <br />
                            {value !== null && (
                              <Grid item xs={12}>
                                <Typography sx={AnswerText}>
                                  {labels[hover !== -1 ? hover : ratingValue]}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        )}

                        {isCheckbox && (
                          <Grid item xs={12} width={"100%"}>
                            <FormGroup>
                              {questions[index].answers.map((item, i) => {
                                return (
                                  <FormControlLabel
                                    value={item.answerText}
                                    name={questions[index].question}
                                    control={
                                      <Checkbox onChange={handleChange} />
                                    }
                                    label={
                                      <Typography sx={AnswerText}>
                                        {strToPascalCase(item.answerText)}
                                      </Typography>
                                    }
                                    key={`${index}-${i}`}
                                  />
                                );
                              })}
                            </FormGroup>
                          </Grid>
                        )}

                        {isVideo && (
                          <Grid item xs={12} width={"100%"}>
                            {recordedChunks.length > 0 ? (
                              <Grid
                                container
                                direction="column"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                rowSpacing={2}
                              >
                                <Grid item xs={12} height={"100%"}>
                                  <ReactPlayer
                                    controls
                                    playsinline={true}
                                    playing={isPreviewPlaying}
                                    width="100%"
                                    className="video"
                                    url={videoFilePath}
                                  />
                                </Grid>

                                <Grid item xs={12} width={"100%"}>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Grid item xs="auto">
                                      <Button
                                        variant="outlined"
                                        color="success"
                                        onClick={() => {
                                          setRecordedChunks([]);
                                        }}
                                        startIcon={
                                          <AutorenewOutlinedIcon
                                            style={{ fontSize: 20 }}
                                          />
                                        }
                                        size="small"
                                      >
                                        <Typography sx={AnswerText}>
                                          Re{`${String.fromCodePoint(8209)}`}do
                                        </Typography>
                                      </Button>
                                    </Grid>

                                    <Grid item xs="auto">
                                      <Button
                                        variant="outlined"
                                        onClick={() =>
                                          setIsPreviewPlaying(!isPreviewPlaying)
                                        }
                                        startIcon={
                                          <PlayCircleFilledWhiteOutlinedIcon
                                            style={{ fontSize: 20 }}
                                          />
                                        }
                                        size="small"
                                      >
                                        <Typography sx={AnswerText}>
                                          Preview
                                        </Typography>
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography sx={NoteText}>
                                    Note: Click on record to start recording
                                  </Typography>
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid
                                container
                                direction="column"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                rowSpacing={2}
                              >
                                <Grid item xs={12} height={"100%"}>
                                  <Webcam
                                    audio={true}
                                    muted={true}
                                    ref={webcamRef}
                                    width="100%"
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  {capturing ? (
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      onClick={handleStopCaptureClick}
                                      startIcon={
                                        <StopCircleOutlinedIcon
                                          style={{ fontSize: 20 }}
                                        />
                                      }
                                      size="small"
                                    >
                                      <Typography sx={AnswerText}>
                                        Stop
                                      </Typography>
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="outlined"
                                      color="success"
                                      startIcon={
                                        <RadioButtonCheckedOutlinedIcon
                                          style={{ fontSize: 20 }}
                                        />
                                      }
                                      size="small"
                                      onClick={handleStartCaptureClick}
                                    >
                                      <Typography sx={AnswerText}>
                                        Record
                                      </Typography>
                                    </Button>
                                  )}
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography sx={NoteText}>
                                    Note: Click on record to start recording
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        )}

                        {isAudio && (
                          <div style={{ width: "100%" }}>
                            {blobURL ? (
                              <div style={{ width: "100%" }}>
                                <div>
                                  <AudioPlayer
                                    elevation={1}
                                    width="100%"
                                    variation="primary"
                                    spacing={1}
                                    debug={false}
                                    src={blobURL}
                                  />
                                </div>
                                <br />
                                <br />
                                <div className="button-container">
                                  <Button
                                    variant="outlined"
                                    color="success"
                                    startIcon={
                                      <AutorenewOutlinedIcon
                                        style={{ fontSize: 20 }}
                                      />
                                    }
                                    size="small"
                                    onClick={() => {
                                      setBlobUrl(null);
                                      setIsAudioRecording(false);
                                    }}
                                  >
                                    <Typography sx={AnswerText}>
                                      Re{`${String.fromCodePoint(8209)}`}do
                                    </Typography>
                                  </Button>
                                </div>
                              </div>
                            ) : (
                              <div style={{ width: "100%" }}>
                                {/* Display timer */}
                                {isAudioRecording ? (
                                  <Grid item xs={12} sx={GridContainer}>
                                    <CountDownTimer
                                      hoursMinSecs={hoursMinSecs}
                                    />
                                  </Grid>
                                ) : (
                                  <Grid item xs={12} sx={GridContainer}>
                                    <div>
                                      <span className="timer-text">00:00</span>
                                    </div>
                                  </Grid>
                                )}

                                <br />
                                <br />
                                <div className="button-container">
                                  {!isAudioRecording ? (
                                    <Button
                                      variant="outlined"
                                      color="success"
                                      startIcon={
                                        <RadioButtonCheckedOutlinedIcon
                                          style={{ fontSize: 20 }}
                                        />
                                      }
                                      onClick={startRecording}
                                      size="small"
                                    >
                                      <Typography sx={AnswerText}>
                                        Record
                                      </Typography>
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      onClick={stopRecording}
                                      startIcon={
                                        <StopCircleOutlinedIcon
                                          style={{ fontSize: 20 }}
                                        />
                                      }
                                      size="small"
                                    >
                                      <Typography sx={AnswerText}>
                                        Stop
                                      </Typography>
                                    </Button>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <br />
                  {isProgressBar && (
                    <Grid xs={12}>
                      <LinearProgress variant="determinate" value={progress} />
                    </Grid>
                  )}
                </div>
              ) : null}
            </CardContent>
            {isPortrait && (
              <CardActions className={classes.cardActionCustom}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  {isPrev ? (
                    <Button variant="contained" onClick={prevQuestion}>
                      <Typography sx={AnswerText}>Prev</Typography>
                    </Button>
                  ) : (
                    <div></div>
                  )}

                  {!isLast ? (
                    <div>
                      {isVideo || isAudio || isText ? (
                        <Button
                          variant="contained"
                          onClick={() => {
                            if (isVideo) {
                              handleDownload();
                            } else if (isAudio) {
                              onAudioSubmit();
                            } else {
                              onTextSubmit();
                            }
                          }}
                          disabled={
                            recordedChunks.length > 0 ||
                            blobURL !== null ||
                            textValue.length > 0
                              ? false
                              : true
                          }
                        >
                          <Typography sx={AnswerText}>Next</Typography>
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={nextQuestion}
                          disabled={
                            value !== "" ? false : isCheckbox ? false : true
                          }
                        >
                          <Typography sx={AnswerText}>Next</Typography>
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div>
                      {isVideo || isAudio || isText ? (
                        <Button
                          variant="contained"
                          onClick={() => {
                            if (isVideo) {
                              handleDownload();
                            } else if (isAudio) {
                              onAudioSubmit();
                            } else {
                              onTextSubmit();
                            }
                          }}
                          disabled={
                            recordedChunks.length > 0 ||
                            blobURL !== null ||
                            textValue.length > 0
                              ? false
                              : true
                          }
                        >
                          <Typography sx={AnswerText}>Submit</Typography>
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={onSubmit}
                          disabled={
                            value !== "" ? false : isCheckbox ? false : true
                          }
                        >
                          <Typography sx={AnswerText}>Submit</Typography>
                        </Button>
                      )}
                    </div>
                  )}
                </Grid>
              </CardActions>
            )}
          </Card>
        </Grid>
        {!isPortrait && (
          <Grid item xs={2}>
            {!isLast ? (
              <div>
                {isVideo || isAudio || isText ? (
                  <Button
                    className={classes.centeredButton}
                    variant="contained"
                    onClick={() => {
                      if (isVideo) {
                        handleDownload();
                      } else if (isAudio) {
                        onAudioSubmit();
                      } else {
                        onTextSubmit();
                      }
                    }}
                    disabled={
                      recordedChunks.length > 0 ||
                      blobURL !== null ||
                      textValue.length > 0
                        ? false
                        : true
                    }
                  >
                    <Typography sx={AnswerText}>Next</Typography>
                  </Button>
                ) : (
                  <Button
                    className={classes.centeredButton}
                    variant="contained"
                    onClick={nextQuestion}
                    disabled={value !== "" ? false : true}
                  >
                    <Typography sx={AnswerText}>Next</Typography>
                  </Button>
                )}
              </div>
            ) : (
              <div>
                {isVideo || isAudio || isText ? (
                  <Button
                    className={classes.centeredButton}
                    variant="contained"
                    onClick={() => {
                      if (isVideo) {
                        handleDownload();
                      } else if (isAudio) {
                        onAudioSubmit();
                      } else {
                        onTextSubmit();
                      }
                    }}
                    disabled={
                      recordedChunks.length > 0 ||
                      blobURL !== null ||
                      textValue.length > 0
                        ? false
                        : true
                    }
                  >
                    <Typography sx={AnswerText}>Submit</Typography>
                  </Button>
                ) : (
                  <Button
                    className={classes.centeredButton}
                    variant="contained"
                    onClick={onSubmit}
                    disabled={value !== "" ? false : isCheckbox ? false : true}
                  >
                    <Typography sx={AnswerText}>Submit</Typography>
                  </Button>
                )}
              </div>
            )}
          </Grid>
        )}
      </Grid>
    );
  }
};

export default Questions;
