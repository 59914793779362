import React, { useEffect, useState } from "react";
import { Rating, Grid, Typography, Divider } from "@mui/material";
import { AnswerText, Labels } from "../assets/css/sxStyles";
import { StarRate } from "@material-ui/icons";
import { capitalizeString, createEvent } from "../common/util";

const CustomRating = (props) => {
  const [value, setValue] = useState(0);

  // for rating hover labels
  const [hover, setHover] = useState(-1);
  const [precision, setPrecision] = useState(1);
  const [labels, setLabels] = useState({});

  const [isMoving, setIsMoving] = useState(false);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    let div = 1;
    if (props.data.range === "1-10") {
      div = 2;
      setPrecision(0.5);
    } else setPrecision(1);

    var labelOption = {};

    if (props.data.answers)
      for (let i = 0; i < props.data.answers.length; i++)
        labelOption[(i + 1) / div] = capitalizeString(props.data.answers[i]);

    setLabels(labelOption);

    if (props.value !== "") setValue(parseFloat(props.value.split("-")[0]));
    else setValue(0);
  }, [setValue, props]);

  const handleTouchMove = (event) => {
    // Calculate the active value based on touch position
    setIsMoving(true);
    const rect = event.currentTarget.getBoundingClientRect();
    const totalStars = 5 / precision;

    const newValue =
      Math.floor(
        ((event.touches[0].clientX - rect.left) / rect.width) * totalStars
      ) * precision;

    setHover(newValue);
    setValue(parseFloat(newValue));
  };

  const handleTouchEnd = () => setIsMoving(false);

  useEffect(() => {
    if (value !== 0 && !isMoving) {
      let timeoutId;
      timeoutId = setTimeout(
        props.onChange(
          createEvent(props.data.question, value + "-" + labels[value], true)
        ),
        5000
      );
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [value, isMoving]);

  const onChange = (event) => {
    event.target.value = event.target.value + "-" + labels[event.target.value];
    setValue(parseFloat(event.target.value));
  };

  return (
    <Grid container gap={1}>
      <Grid item xs={12} display={"flex"} justifyContent={"center"}>
        <Rating
          name={props.data.question}
          value={value}
          precision={precision}
          sx={{ fontSize: 55.5 }}
          disabled={props.disabled}
          max={5}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onChange={onChange}
          emptyIcon={<StarRate style={{ fontSize: 55, opacity: 0.55 }} />}
        />
      </Grid>

      {labels && (
        <Grid container>
          <Grid
            item
            xs={12}
            display={"flex"}
            mx={isMobile ? 7 : 14}
            justifyContent={"space-between"}
          >
            <Typography sx={Labels}> {labels[1]}</Typography>
            <Typography sx={Labels} textAlign={"end"}>
              {labels[5]}
            </Typography>
          </Grid>

          <Grid item my={2} xs={12} px={isMobile ? 4 : 10}>
            <Divider light />
          </Grid>

          <Grid item xs={12} px={isMobile ? 7 : 14}>
            <Typography sx={AnswerText} fontWeight={700}>
              {labels[hover !== -1 ? hover : value]}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CustomRating;
