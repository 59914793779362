import React, { useState, useEffect } from "react";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import Unsubscribe from "./pages/unsubscribe";
import { I18n, Auth } from "aws-amplify";
import Registration from "./pages/registration";
import logo from "./assets/images/Logo_Pyxis_Blue.png";

import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import "antd/dist/antd.css";
import { Typography } from "antd";
import { Grid } from "@mui/material";
import { errorData, errorDataFB } from "./assets/errorInfo";
import { getSocialMediaPlatform, params } from "./common/util";
import Screening from "./pages/screening";

const { Text } = Typography;

I18n.setLanguage("en");
I18n.putVocabulariesForLanguage("en", {
  "Create Account": "Register me as a Pyxis Panelist", // Tab header
});

const components = {
  Header() {
    return <div style={{ height: "40px" }}>&nbsp;</div>;
  },
};

const services = {
  async handleSignUp(formData) {
    let { username, password, attributes } = formData;
    // custom username
    username = username.toLowerCase();
    attributes.email = attributes.email.toLowerCase();

    return Auth.signUp({
      username,
      password,
      attributes,
    });
  },
};

function App() {
  const { user } = useAuthenticator((context) => [context.user]);
  const { route } = useAuthenticator((context) => [context.route]);
  const [signupMode, setSignupMode] = useState(false);
  const [isPlatformWorking, setIsPlatformWorking] = useState(true);
  const [errorInfo, setErrorInfo] = useState(errorData);

  useEffect(() => {
    const url = navigator.userAgent;
    const platform = getSocialMediaPlatform(url);

    if (!params.id) setSignupMode(true);

    if (platform === "Android Facebook platform") setErrorInfo(errorDataFB);

    if (
      [
        "Android Facebook platform",
        "Android Messenger platform",
        "Android Instagram platform",
      ].includes(platform)
    )
      setIsPlatformWorking(false);

    // Request access to the microphone and camera
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((stream) => {
        // User granted access, you can now use the microphone and camera
        // for audio and video recording or streaming
      })
      .catch((error) => {
        // User denied access or an error occurred
        console.error("Error accessing microphone/camera:", error);
      });
  }, []);

  const styleApp = {
    // backgroundImage: `url('https://picsum.photos/id/237/1000?blur')`,
    // backgroundColor : "blue",
    // backgroundSize : "cover",
    // color : "#1764C0"
  };

  if (!isPlatformWorking) {
    return (
      <div className="App" style={styleApp}>
        <header className="App-header">
          <span className="App-poweredBy">
            Powered by{" "}
            <img src={logo} loading="lazy" className="App-logo" alt="logo" />
          </span>
        </header>
        <div className="App-content">
          <Grid container py={2} px={4} alignSelf={"center"}>
            <Grid item xs={12}>
              <Text className="">
                To open a link from Facebook Messenger in the Chrome browser,
                follow these steps:
              </Text>
            </Grid>
            <Grid item xs={12}>
              <Text component="ul" className="list">
                {errorInfo.map((item, index) => {
                  return (
                    <Grid key={index} item xs={12}>
                      <Text component="li" className="list-item">
                        <Grid container>
                          <Grid item xs={12}>
                            {item.text}
                          </Grid>

                          {item.img && (
                            <Grid item xs={12}>
                              <img
                                src={item.img}
                                loading="lazy"
                                className="info"
                                alt="info-images"
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Text>
                    </Grid>
                  );
                })}
              </Text>
            </Grid>
            <Grid item xs={12}>
              <Text className="">
                If you encounter any challenges or need additional support,
                please don't hesitate to inform us. We are here to assist you.
                Thank you!
              </Text>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  } else {
    return route === "authenticated" && user ? (
      signupMode ? (
        <div className="App" style={styleApp}>
          <header className="App-header">
            <span className="App-poweredBy">
              Powered by{" "}
              <img src={logo} loading="lazy" className="App-logo" alt="logo" />
            </span>
          </header>
          <div className="App-content">
            <Registration />
          </div>
        </div>
      ) : (
        <Authenticator>
          {({ signOut, user }) => (
            <div className="App" style={styleApp}>
              <header className="App-header">
                <img
                  src={logo}
                  loading="lazy"
                  className="App-logo"
                  alt="logo"
                />
                <button onClick={signOut}>Sign out</button>
              </header>
              <div className="App-content">
                {window.location.pathname === "/unsubscribe" ? (
                  <Unsubscribe />
                ) : (
                  <Screening />
                )}
              </div>
            </div>
          )}
        </Authenticator>
      )
    ) : signupMode && window.location.pathname !== "/unsubscribe"? (
      <div className="App" style={styleApp}>
        <header className="App-header">
          {console.log("user", user)}
          <span className="App-poweredBy">
            Powered by{" "}
            <img src={logo} loading="lazy" className="App-logo" alt="logo" />
          </span>
        </header>
        <div className="App-content">
          <Authenticator
            services={services}
            components={components}
            initialState="signUp"
          >
            {({ signOut, user }) => (
              <div className="App-content">{/*<Registration/>*/}</div>
            )}
          </Authenticator>
        </div>
      </div>
    ) : (
      <div className="App" style={styleApp}>
        <header className="App-header">
          <span className="App-poweredBy">
            Powered by{" "}
            <img src={logo} loading="lazy" className="App-logo" alt="logo" />
          </span>
        </header>
        <div className="App-content">
          {window.location.pathname === "/unsubscribe" ? (
            <Unsubscribe />
          ) : (
            <Screening onSignUp={() => setSignupMode(true)} />
          )}
          {/*<Study/>*/}
        </div>
      </div>
    );
  }
}

export default App;
