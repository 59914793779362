import React, { useEffect, useState } from "react";

import { Grid, TextField, Button, Typography } from "@mui/material";
import { AnswerText } from "../assets/css/sxStyles";

// icons
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import { Storage } from "aws-amplify";
import { Progress } from "antd";
import { params } from "../common/util";

const TextCapture = (props) => {
  const [value, setValue] = useState(props.value);
  const [isDisabled, setIsDisabled] = useState(
    props.value.length > 3 ? false : true
  );

  const [isTesting, setIsTesting] = useState(false);

  // For progress bar
  const [progress, setProgress] = useState(0);
  const [isProgressBar, setIsProgressBar] = useState(false);

  useEffect(() => {
    if (Object.keys(props.userData).length === 0) {
      setIsTesting(true);
    } else {
      setIsTesting(false);
    }
  }, [props.userData]);

  useEffect(() => {
    if (progress === 100) {
      setIsProgressBar(false);
      setProgress(0);
    }
  }, [progress]);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (event.target.value.length > 3) setIsDisabled(false);
    else setIsDisabled(true);
    props.onTextValueChange(event);
  };

  const handleSubmit = async (event) => {
    const userData = props.userData;
    let newFileName = `${params.id}/${props.data.title}-${userData.firstName}${userData.lastInitial}.txt`;

    if (!isTesting) {
      try {
        setIsProgressBar(true);
        const result = await Storage.put(newFileName, value, {
          contentType: "text/plain", // contentType is optional
          progressCallback(progress) {
            setProgress(((progress.loaded / progress.total) * 100).toFixed(0));
          },
          errorCallback: (err) => {
            console.error("Unexpected error while uploading", err);
          },
        });
        newFileName = result.key;
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    }
    event.target = {
      name: props.data.question,
      value: newFileName,
    };
    props.onChange(event);
  };

  return (
    <Grid container gap={2} fullWidth marginTop={2}>
      <Grid item xs={12} fullWidth>
        <TextField
          id="outlined-multiline-flexible"
          multiline
          label="Enter your answer"
          inputProps={{ style: AnswerText }}
          className="text-area"
          placeholder="Enter your text here"
          maxRows={10}
          value={value}
          fullWidth
          disabled={isProgressBar || props.disabled}
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12}>
        {isProgressBar ? (
          <Progress
            type="circle"
            percent={progress}
            width={50}
            strokeWidth={5}
            strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
          />
        ) : (
          <Button
            variant="outlined"
            startIcon={<BackupOutlinedIcon style={{ fontSize: 20 }} />}
            size="small"
            disabled={isProgressBar || props.disabled || isDisabled}
            onClick={handleSubmit}
          >
            <Typography sx={AnswerText}>Submit</Typography>{" "}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default TextCapture;
