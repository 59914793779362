import React, { useState, useEffect } from "react";
import { Typography, Grid, Divider } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import "antd/dist/antd.css";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  //   background: isDragging ? "white" : "white",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  //   background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: 0,
});

const ReorderList = (props) => {
  const [itemsList, setItemsList] = useState([]);

  useEffect(() => {
    setItemsList(props.items.map((item) => item));
  }, [props.items, setItemsList]);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) return;

    const items = reorder(
      itemsList,
      result.source.index,
      result.destination.index
    );

    props.onChange(items);
    setItemsList(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {itemsList.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <Grid container wrap="nowrap" className="item">
                      <Grid item xs={1}>
                        <Typography variant="subtitle1">{index + 1}</Typography>
                      </Grid>

                      <Grid item xs={10}>
                        <Typography variant="subtitle1" noWrap>
                          <Divider orientation="vertical" flexItem />
                          {item.content}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} container justifyContent={"flex-end"}>
                        <DragIndicatorIcon style={{ color: "#bcbcbc" }} />
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ReorderList;
