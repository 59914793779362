import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SectionalQuestions from "./SectionalQuestions";
import {
  clear,
  getLocalStorage,
  removeItem,
  setLocalStorage,
} from "../Models/data/useLocalStorage";
import { API } from "aws-amplify";
import { updateResponseUserDetail } from "../graphql/mutations";

import {
  AnswerText,
  SectionHeaderText,
  SectionsContainer,
} from "../assets/css/sxStyles";
import { Tabs, Steps, Result, Progress } from "antd";

import "../App.css";
import { QuestionText } from "../assets/css/sxStyles";
import { params } from "../common/util";
import { OnlyMedia } from "../common/constant";

const { Step } = Steps;

const cardStyle = {
  padding: "0 1rem 1em 1rem",
};

const Sections = ({
  QuestionsData,
  userData,
  onSignUp,
  sectionData,
  completedMsg,
  isJournaling,
}) => {
  const [expanded, setExpanded] = useState("panel0");

  const [sections, setSections] = useState([]);
  const [questions] = useState(QuestionsData);
  const [type, setType] = useState("S");
  const [disabledTabsIndex, setDisabledTabsIndex] = useState(0);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

  // for steps
  const [current, setCurrent] = useState(0);

  // for tabs
  const [keyIndex, setKeyIndex] = useState("0");

  // for checking mobile
  const [isMobile, setIsMobile] = useState(false);

  const [questionsProgress, setQuestionsProgress] = useState(0);
  const [isTesting, setIsTesting] = useState(false);
  const [isSurveyCompleted, setIsSurveyCompleted] = useState(
    userData.isFinished
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isLastSurveySection, setIsLastSurveySection] = useState(false);

  // For screening data
  const screeningData = getLocalStorage(`${params.id}-screenoutdata`);

  useEffect(() => {
    let dataArr = [];
    QuestionsData.forEach((element, index) => {
      if (element.dependencies.length > 0) {
        element.index = index;
        dataArr.push(element);
      }
    });

    if (Object.keys(userData).length === 0) setIsTesting(true);
    else setIsTesting(false);

    setLocalStorage(`disabledQuest-${params.id}`, dataArr);

    let sectionsDataList = [];
    sectionData.forEach((element) => {
      let dependentAnswersCount = 0;
      for (let i = element.start; i <= element.end; i++) {
        let isIdPresent = dataArr.some((obj) => obj.index === i);
        if (isIdPresent) {
          dependentAnswersCount++;
        }
      }

      element.isHidden =
        element.end - element.start + 1 === dependentAnswersCount
          ? true
          : false;
      sectionsDataList.push(element);
    });

    setLocalStorage(`disabledSections-${params.id}`, sectionsDataList);

    setSections(...[], sectionData);
  }, []);

  useEffect(() => {
    function detectMob() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ];

      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    }

    if (detectMob()) {
      setType("T");
      setIsMobile(true);
    }
  }, []);

  const getLastSectionIndex = () => {
    const sData = getLocalStorage(`disabledSections-${params.id}`);

    const lastIndex = sData.reverse().findIndex((element) => !element.isHidden);
    let lastFalseIndex = 0;

    if (lastIndex !== -1) lastFalseIndex = sData.length - 1 - lastIndex;

    return lastFalseIndex;
  };

  const nextSection = (i) => {
    const nextInd = checkNextSectionIndex(i, true);
    setCurrent(nextInd);

    const lastSectionIndex = getLastSectionIndex();

    if (nextInd >= lastSectionIndex) setIsLastSurveySection(true);
    else setIsLastSurveySection(false);
  };

  const next = () => nextSection(current + 1);

  const handleSectonsListChange = (sectionsDataList) => {
    setSections(...[], sectionsDataList);

    const answeredDataLen =
      getLocalStorage(`answers-${params.id}-${userData.emailHash}`)
        .length || 0;

    const disabledQuestLen =
      getLocalStorage(`disabledQuest-${params.id}`).length || 0;

    setQuestionsProgress(
      ((answeredDataLen / (questions.length - disabledQuestLen)) * 100).toFixed(
        0
      )
    );
  };

  const checkNextSectionIndex = (ind, isAdd) => {
    let dataArray = getLocalStorage(`disabledSections-${params.id}`);

    if (dataArray[ind].isHidden)
      if (isAdd) return checkNextSectionIndex(ind + 1, true);
      else return checkNextSectionIndex(ind - 1, false);
    else {
      let answeredData = getLocalStorage(
        `answers-${params.id}-${userData.emailHash}`
      );

      if (answeredData && answeredData.length > 0) {
        const isPresent = answeredData.some(
          (element) => element.id === dataArray[ind]?.end
        );

        if (!isPresent) setIsSubmitButtonDisabled(true);
        else setIsSubmitButtonDisabled(false);
      }
      return ind;
    }
  };

  const prev = () => {
    setCurrent(current - 1);
    setIsLastSurveySection(false);
    setIsNextButtonDisabled(false);
  };

  const onChange = (key) => setKeyIndex(key);

  const handleChange = (panel) => (event, isExpanded) =>
    setExpanded(isExpanded ? panel : false);

  const handleSectionChange = (e) => {
    const lastSectionIndex = getLastSectionIndex();

    if (disabledTabsIndex <= e) setDisabledTabsIndex(e);
    else setIsNextButtonDisabled(false);

    if (e > lastSectionIndex) {
      setIsSubmitButtonDisabled(false);
    } else {
      switch (type) {
        case "A":
          setExpanded("panel" + e);
          break;
        case "S":
          nextSection(e);
          break;
        case "T":
          setKeyIndex(checkNextSectionIndex(e, true).toString());
          break;
        default:
          break;
      }
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let answerData = getLocalStorage(
      `answers-${params.id}-${userData.emailHash}`
    ).filter((obj) => obj.answer !== "");

    if (screeningData.length > 0) answerData.push(...screeningData);

    let response = {
      emailHash: userData.emailHash,
      surveyID: params.id,
      answers: JSON.stringify(
        answerData.map((a, i) => {
          return {
            question: a.question,
            answer: a.answer,
            isInS3: OnlyMedia.includes(a.type),
          };
        })
      ),
      isFinished: true,
    };

    console.log(JSON.parse(response.answers));
    if (!isTesting) {
      try {
        const updateResponse = await API.graphql({
          query: updateResponseUserDetail,
          variables: { input: response },
          authMode: "AWS_IAM",
        });
        console.log(updateResponse);
        clear();
        setIsLoading(false);
        setIsSurveyCompleted(true);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      clear();
      setIsSurveyCompleted(true);
    }

    if (isJournaling && !isTesting)
      setLocalStorage(`${params.id}-user`, userData);
  };

  if (isSurveyCompleted) {
    removeItem(`answers-${params.id}-${userData.emailHash}`);
    removeItem(`user-${params.id}`);
    return (
      <div className="card" style={cardStyle}>
        <Result
          status="success"
          title={<Typography sx={QuestionText}>Survey Completed</Typography>}
          subTitle={
            <>
              <Typography sx={AnswerText}>{completedMsg}</Typography>
              <br />
              {onSignUp && (
                <Typography sx={AnswerText}>
                  Are you interested in joining our panelist database for
                  exciting paid study opportunities in the future?
                </Typography>
              )}
            </>
          }
          extra={
            onSignUp && (
              <Button onClick={onSignUp} type="primary">
                Sign me up for paid studies!
              </Button>
            )
          }
        />
      </div>
    );
  } else {
    return (
      <Grid container rowSpacing={2} sx={SectionsContainer}>
        <Grid container>
          <Grid item xs={12}>
            <Progress
              strokeLinecap="round"
              strokeColor={{
                "0%": "#108ee9",
                "100%": "#87d068",
              }}
              percent={questionsProgress}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {type === "T" && (
            <Tabs
              size="small"
              activeKey={keyIndex}
              type="card"
              centered
              onChange={onChange}
            >
              {sections.map((element, index) => {
                if (element.isHidden) return null;
                else
                  return (
                    <Tabs.TabPane
                      tab={element.title}
                      disabled={index > disabledTabsIndex ? true : false}
                      key={index}
                    >
                      <SectionalQuestions
                        QuestionsData={QuestionsData}
                        start={element?.start}
                        end={element?.end}
                        userData={userData}
                        index={index}
                        isMobile={isMobile}
                        onSectionsListChange={handleSectonsListChange}
                        onChange={handleSectionChange}
                      />
                    </Tabs.TabPane>
                  );
              })}
            </Tabs>
          )}

          {type === "S" && (
            <Grid container>
              <Steps current={current}>
                {sections.map((item) => {
                  return (
                    !item.isHidden && (
                      <Step key={item.title} title={item.title} />
                    )
                  );
                })}
              </Steps>

              <Grid item xs={12} className="steps-content" marginY={5}>
                <SectionalQuestions
                  QuestionsData={QuestionsData}
                  start={sections[current]?.start}
                  end={sections[current]?.end}
                  userData={userData}
                  index={current}
                  isMobile={isMobile}
                  onSectionsListChange={handleSectonsListChange}
                  onChange={handleSectionChange}
                />
              </Grid>

              <Grid item xs={12} className="steps-action">
                {current > 0 && (
                  <Button
                    style={{
                      margin: "0 8px",
                    }}
                    variant="contained"
                    onClick={() => prev()}
                  >
                    Previous
                  </Button>
                )}

                {isLastSurveySection ? (
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={isSubmitButtonDisabled}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    disabled={isNextButtonDisabled}
                    onClick={() => next()}
                  >
                    Next
                  </Button>
                )}
              </Grid>
            </Grid>
          )}

          {type === "A" && (
            <Grid item xs={12} height={"100%"}>
              {sections.map((element, index) => {
                return (
                  <Grid item xs={12} key={element.start + index} marginY={1}>
                    <Accordion
                      expanded={expanded === "panel" + index}
                      onChange={handleChange("panel" + index)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography sx={SectionHeaderText}>
                          {element.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <SectionalQuestions
                          QuestionsData={questions}
                          start={element.start}
                          end={element.end}
                          userData={userData}
                          index={index}
                          isMobile={isMobile}
                          onSectionsListChange={handleSectonsListChange}
                          onChange={handleSectionChange}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>

        {!["S"].includes(type) && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              marginY={4}
              onClick={handleSubmit}
              disabled={isSubmitButtonDisabled}
            >
              <Typography sx={SectionHeaderText}>Submit</Typography>
            </Button>
          </Grid>
        )}

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" size={150} />
        </Backdrop>
      </Grid>
    );
  }
};

export default Sections;
